import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserActions } from '../../state/user/user.actions';

@Injectable()
export class HasPortalRoleInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          // This error code is returned when user has no permission to access the portal
          if (error.error?.code === 'employee-has-not-access') {
            this.store.dispatch(UserActions.logoutUser({}));
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
