import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MaterialModule } from '@material/material.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { PlatformModule } from '@angular/cdk/platform';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  TranslateModule,
  TranslateLoader,
  TranslateCompiler,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { languageReducer } from './state/language.reducer';
import { LanguageEffects } from './state/language.effects';
import { metaLogoutUser } from './state/user/user.reducer';

import { httpInterceptorProviders } from './shared/http-interceptors';
import { SseService } from './shared/services/sse.service';
import * as fromUser from './state/user/user.reducer';
import { UserEffects } from './state/user/user.effects';
import { environment } from '../environments/environment';
import * as fromSystemAlerts from '@state/system-alerts/system-alerts.reducer';
import { SystemAlertsEffects } from '@state/system-alerts/system-alerts.effects';
import { UserService } from './shared/services/user.service';
import { InfoDialogComponent } from './shared/components/info-dialog/info-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RemoveModalComponent } from './shared/components/remove-modal/remove-modal.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { CompanyAttributesModule } from './state/company-attributes/company-attributes.module';

import { registerLocaleData } from '@angular/common';
import localeNo from '@angular/common/locales/no';
import { GeneralStateModule } from './state/general-state/general-state.module';
import {
  chatFeatureKey,
  chatReducer,
} from './modules/chats/store/chat.reducer';
import { ChatEffects } from './modules/chats/store/chat.effects';
import { PermissionsModule } from './modules/permissions/permissions.module';

registerLocaleData(localeNo);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    InfoDialogComponent,
    RemoveModalComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,

    StoreModule.forRoot(
      {
        language: languageReducer,
      },
      { metaReducers: [metaLogoutUser] },
    ),
    EffectsModule.forRoot([LanguageEffects]),
    StoreModule.forFeature(fromUser.usersFeatureKey, fromUser.reducer),
    StoreModule.forFeature(
      fromSystemAlerts.systemAlertsFeatureKey,
      fromSystemAlerts.reducer,
    ),
    EffectsModule.forFeature([UserEffects, SystemAlertsEffects]),
    StoreModule.forFeature(chatFeatureKey, chatReducer),
    EffectsModule.forFeature([ChatEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    MaterialModule,
    HttpClientModule,
    MatDialogModule,
    PlatformModule,

    CompanyAttributesModule,
    GeneralStateModule,

    PermissionsModule,
  ],
  providers: [httpInterceptorProviders, SseService, UserService],
  bootstrap: [AppComponent],
})
export class AppModule {}
