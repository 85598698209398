import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  APIDataObject,
  CreateTeamRequest,
  EditTeamRequest,
  Employee,
  Team,
  TeamItem,
  TeamMember,
} from '../../shared/interfaces';
import { ApiErrorInterface } from '../../shared/interfaces/api-error.interface';
import { Status } from '../../shared/constants/status';

export const TeamsActions = createActionGroup({
  source: 'Teams',
  events: {
    'Loading State Teams': props<{ state: boolean }>(),
    Teams: props<{ companyId: number }>(),
    'Teams Success': props<{
      data: unknown;
    }>(),
    'Teams Failure': props<{ error: unknown }>(),
    'Get Teams': props<{
      offset?: number;
      limit?: number;
      order?: string;
    }>(),
    'Get Teams For Filter': props<{
      companyId?: number;
      isIndividualInsights?: boolean;
      offset?: number;
      limit?: number;
    }>(),
    'Get Teams For Filter Success': props<{
      teams: Pick<TeamItem, 'name' | 'teamId'>[];
      offset?: number;
      limit?: number;
      total?: number;
    }>(),
    'Get Teams For Community Health': props<{
      companyId: number;
      offset?: number;
      limit?: number;
      search?: string;
    }>(),
    'Get Teams For Community Health Success': props<{
      teams: Pick<TeamItem, 'name' | 'teamId'>[];
      offset?: number;
      limit?: number;
      total?: number;
    }>(),
    'Get Teams For Filter Failure': props<{ error: ApiErrorInterface }>(),
    'Set Teams Total': props<{ total: number }>(),
    'Set Employee Total': props<{ total: number }>(),
    'Set Team Filter Params': props<{
      responsibleEmployeeId?: number;
      numberOfMembers?: number;
      teamIds?: number[];
      offset?: number;
      limit?: number;
      order?: string;
    }>(),
    'Set Employee Params': props<{
      offset?: number;
      limit?: number;
    }>(),
    'Get Teams Success': props<{
      teams: TeamItem[];
      offset?: number;
      limit?: number;
      total?: number;
    }>(),
    'Create Team': props<{ companyId: number; body: CreateTeamRequest }>(),
    'Create Team Success': props<{ team: Team & TeamItem }>(),
    'Create Team Failure': props<{ error: ApiErrorInterface }>(),
    'Patch Team': props<{
      companyId: number;
      teamId: number;
      body: EditTeamRequest;
    }>(),
    'Patch Team Success': props<{ team: Team & TeamItem }>(),
    'Patch Team Failure': props<{ error: ApiErrorInterface }>(),
    'Delete Team': props<{ companyId: number; id: number }>(),
    'Delete Team Success': props<{ teamId: number }>(),
    'Get Employees': props<{ companyId: number }>(),
    'Get Employees Success': props<{
      employees: Employee[];
      offset?: number;
    }>(),
    'Get Team Members': props<{
      companyId: number;
      teamId: number;
      offset: number;
      limit?: number;
      search?: string;
      request?: Status;
    }>(),
    'Get Team Members Success': props<{ members: APIDataObject<TeamMember> }>(),
    'Update Team Members': props<{
      companyId: number;
      teamId: number;
      addedMemberIds: number[];
      deletedMemberIds: number[];
    }>(),
    'Update Team Members Success': props<{
      teamId: number;
      numberOfMembers: number;
    }>(),
    'Get Team': props<{ companyId: number; teamId: number }>(),
    'Get Team Success': props<{ team: Team }>(),
    'Clear Teams': emptyProps(),
  },
});
