export enum CHAT_HEADER_MODE {
  CHAT = 'chat',
  ADD_PRIVATE_CHAT = 'add-private-chat',
  ADD_GROUP_CHAT = 'add-group-chat',
  EDIT_GROUP_CHAT = 'edit-group-chat',
}

export enum SOCKET_EVENTS {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  EXCEPTION = 'exception',
  CHAT_LIST = 'chat:list',
  CHAT = 'chat',
  CHAT_CREATE = 'chat:create',
  CHAT_TRIGGER = 'chat:trigger',
  CHAT_HISTORY = 'chat:history',
  CHAT_INFO = 'chat:info',
  CHAT_LEAVE = 'chat:leave',
  CHAT_DELETE = 'chat:delete',
  CHAT_EDIT = 'chat:edit',
  MEMBER_STATUS = 'member:status',
  MESSAGE_NEW = 'message:new',
  MESSAGE_TYPING = 'message:typing',
  MESSAGE_STATUS = 'message:status',
  MESSAGE_EDIT = 'message:edit',
  MESSAGE_DELETE = 'message:delete',
  MEMBER_ADD = 'member:add',
  MEMBER_DELETE = 'member:delete',
  MESSAGE_LOG = 'message:log',
}

export enum CHAT_TYPE {
  PRIVATE = 'PRIVATE',
  GROUP = 'GROUP',
}

export enum MESSAGE_STATUS {
  DELIVERED = 'DELIVERED',
  SEEN = 'SEEN',
}

export enum MESSAGE_TYPE {
  SYSTEM_CREATE_PRIVATE_CHAT = 'SYSTEM_CREATE_PRIVATE_CHAT',
  SYSTEM_CREATE_GROUP_CHAT = 'SYSTEM_CREATE_GROUP_CHAT',
  SYSTEM_EDIT_GROUP_CHAT = 'SYSTEM_EDIT_GROUP_CHAT',
  SYSTEM_LEAVE_GROUP_CHAT = 'SYSTEM_LEAVE_GROUP_CHAT',
  MESSAGE = 'MESSAGE',
}

export enum CHAT_HISTORY_TYPE {
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
}
