import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import {
  addChatMembers,
  addChatMembersSuccess,
  createGroupChat,
  createGroupChatSuccess,
  createPrivateChat,
  createPrivateChatFailure,
  createPrivateChatSuccess,
  deleteChat,
  deleteChatMessage,
  deleteChatMessageSuccess,
  deleteChatSuccess,
  editChat,
  editChatSuccess,
  loadAttachedFiles,
  loadAttachedFilesSuccess,
  loadAttachedPictures,
  loadAttachedPicturesSuccess,
  loadChat,
  loadChatHistory,
  loadChatHistoryPending,
  loadChatHistorySuccess,
  loadChatsList,
  loadChatsListSuccess,
  loadChatSuccess,
  loadEmployeeListSuccess,
  logChatMessage,
  logChatMessageSuccess,
  markMessagesAsRead,
  removeChatMember,
  removeChatMemberSuccess,
  sendMessage,
  sendMessageSuccess,
  setEmployeeListParams,
  setPrivateActiveChatId,
} from './chat.actions';
import { SocketControllerService } from '../services/socket-controller.service';
import { of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { activePrivateChat } from './chat.selectors';
import { RestApiService } from '@modules/chats/services/rest-api.service';
import { SystemAlertsActions } from '@state/system-alerts/system-alerts.actions';

@Injectable()
export class ChatEffects {
  constructor(
    private actions$: Actions,
    private socketControllerService: SocketControllerService,
    private restApiService: RestApiService,
    private store: Store,
    private t: TranslateService,
  ) {}

  loadChatList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadChatsList),
      switchMap(() => this.socketControllerService.getChatList()),
      map((chatList) => loadChatsListSuccess({ chatList })),
    );
  });

  loadChatHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadChatHistory),
      tap(() => loadChatHistoryPending()),
      switchMap(({ chatHistoryRequest }) =>
        this.socketControllerService.getChatsHistory(chatHistoryRequest),
      ),
      tap((chatHistory) =>
        this.socketControllerService.markMessageAsReadFromChatHistory(
          chatHistory,
        ),
      ),
      map((chatHistory) => loadChatHistorySuccess({ chatHistory })),
    );
  });

  loadMembersList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setEmployeeListParams),
      switchMap((params) =>
        this.socketControllerService.getEmployeeList(params),
      ),
      concatMap((employeeList) => [loadEmployeeListSuccess({ employeeList })]),
    );
  });

  createGroupChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createGroupChat),
      switchMap(({ data }) =>
        this.socketControllerService.createGroupChat(data),
      ),
      map((chatItem) => createGroupChatSuccess({ chatItem })),
    );
  });

  createPrivateChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createPrivateChat),
      switchMap(({ data }) =>
        this.socketControllerService.createPrivateChat(data),
      ),
      map((chatItem) => createPrivateChatSuccess({ chatItem })),
      catchError(() => of(createPrivateChatFailure)),
    );
  });

  editChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editChat),
      withLatestFrom(this.store.select(activePrivateChat)),
      switchMap(([{ chatEdit }, activeChat]) => {
        return this.socketControllerService.editChat({
          ...chatEdit,
          options: {
            ...activeChat.options,
            ...chatEdit.options,
          },
        });
      }),
      map((chatItem) => editChatSuccess({ chatItem })),
    );
  });

  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendMessage),
      switchMap((data) =>
        this.socketControllerService.sendMessage(data.message),
      ),
      map((chatMessage) => sendMessageSuccess({ chatMessage })),
    );
  });

  deleteChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteChat),
      switchMap(({ chatId }) =>
        this.socketControllerService.deleteChat(chatId),
      ),
      map(({ chatId }) => deleteChatSuccess({ chatId })),
    );
  });

  loadChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setPrivateActiveChatId),
      filter(({ id }) => Boolean(id)),
      switchMap(({ id }) => this.socketControllerService.loadChat(id)),
      concatMap((chat) => [loadChatSuccess({ chat }), markMessagesAsRead({chatId: chat.chatId})]),
    );
  });

  deleteChatMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(removeChatMember),
      switchMap(({ chatId, externalId }) =>
        this.socketControllerService.removeChatMember(chatId, externalId),
      ),
      map((chatItem) => removeChatMemberSuccess({ chatItem })),
    );
  });

  addChatMembers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addChatMembers),
      switchMap(({ chatId, userIds }) =>
        this.socketControllerService.addChatMembers(chatId, userIds),
      ),
      map((members) => addChatMembersSuccess({ members })),
    );
  });

  deleteChatMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteChatMessage),
      switchMap(({ id }) => this.socketControllerService.deleteChatMessage(id)),
      map((message) => deleteChatMessageSuccess({ message })),
    );
  });

  loadAttachedFiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAttachedFiles),
      switchMap(({ chatId }) => this.restApiService.getAttachedFiles(chatId)),
      map(({ data }) => loadAttachedFilesSuccess({ attachedFiles: data })),
    );
  });

  loadAttachedPictures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAttachedPictures),
      switchMap(({ chatId }) =>
        this.restApiService.getAttachedPictures(chatId),
      ),
      map(({ data }) =>
        loadAttachedPicturesSuccess({ attachedPictures: data }),
      ),
    );
  });

  logChatMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logChatMessage),
      switchMap(({ messageId }) =>
        this.socketControllerService.logChatMessage(messageId).pipe(
          catchError((error) => {
            this.store.dispatch(
              SystemAlertsActions.showErrorSystemAlert({
                title: error.message,
              }),
            );
            return throwError(() => new Error(error));
          }),
        ),
      ),
      map(({ messageId }) => {
        this.store.dispatch(
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant('chats.message-has-been-saved-to-notes'),
          }),
        );
        return logChatMessageSuccess({ messageId });
      }),
    );
  });
}
