const NOTIFICATION = `
<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3612 24.0198C21.933 26.1537 20.6666 28.3471 18.5328 28.9188C16.3989 29.4906 14.2056 28.2243 13.6338 26.0904" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M4.64476 10.25C3.26289 11.6048 2.72283 13.6203 3.24216 15.4845" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M16.6954 7.65462C17.101 6.92489 17.2346 6.04289 17.0015 5.17276C16.525 3.39454 14.6972 2.33926 12.919 2.81574C11.1408 3.29221 10.0855 5.12 10.5619 6.89822C10.7951 7.76835 11.3518 8.46538 12.0679 8.89454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M27.5917 8.96015C27.1094 7.08601 25.6339 5.61055 23.7598 5.12817" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M23.8208 12.5939C23.3449 10.8177 22.0743 9.33243 20.2886 8.46479C18.5029 7.59714 16.3484 7.41821 14.2989 7.96736C12.2495 8.5165 10.4731 9.74874 9.36042 11.393C8.24778 13.0372 7.89008 14.9588 8.366 16.735C9.34732 20.3973 8.86822 23.1368 8.08219 25.0138C7.49354 26.4196 7.19922 27.1224 7.24592 27.2897C7.29615 27.4697 7.35801 27.5405 7.5296 27.6145C7.68913 27.6832 8.33703 27.5096 9.63284 27.1624L27.9374 22.2577C29.2332 21.9105 29.8811 21.7369 29.9849 21.5976C30.0965 21.4478 30.1147 21.3555 30.0682 21.1745C30.025 21.0063 29.4187 20.5447 28.206 19.6217C26.5868 18.3891 24.8021 16.2562 23.8208 12.5939Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</svg>
`;

export default NOTIFICATION;
