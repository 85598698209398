<h2 mat-dialog-title>
  <span>{{ data.title }}</span>
</h2>
<div mat-dialog-content>
  <div class="message">
    {{ data.message }}
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="cancel()">
    {{ data.cancelName || ('common.cancel' | translate) }}
  </button>
  <button
    mat-flat-button
    [color]="data.modalType === 'confirm' ? 'primary' : 'warn'"
    cdkFocusInitial
    (click)="confirm()"
  >
    {{ data.actionName }}
  </button>
</div>
