const FILE_ICON = `
<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Solid / file">
<g id="Solid">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8841 1.62681C10.8851 1.64669 10.8851 1.65765 10.8851 1.67957L10.8851 5.35984C10.8851 5.57016 10.885 5.77499 10.8992 5.94845C10.9148 6.13895 10.9515 6.36373 11.0668 6.58991C11.2265 6.90352 11.4815 7.15848 11.7951 7.31827C12.0213 7.43352 12.2461 7.47024 12.4366 7.48581C12.61 7.49998 12.8149 7.49995 13.0252 7.49993L16.7055 7.49993C16.7274 7.49993 16.7383 7.49993 16.7581 7.50087C17.1697 7.52051 17.5314 7.88241 17.5508 8.29394C17.5518 8.31379 17.5518 8.32026 17.5518 8.3332V14.3677C17.5518 15.0385 17.5518 15.5921 17.5149 16.0431C17.4767 16.5115 17.3945 16.9421 17.1884 17.3466C16.8689 17.9738 16.3589 18.4837 15.7317 18.8033C15.3273 19.0094 14.8967 19.0915 14.4283 19.1298C13.9773 19.1666 13.4237 19.1666 12.7529 19.1666H7.35066C6.67986 19.1666 6.12623 19.1666 5.67524 19.1298C5.20682 19.0915 4.77624 19.0094 4.37179 18.8033C3.74458 18.4837 3.23465 17.9738 2.91507 17.3466C2.709 16.9421 2.62686 16.5115 2.58859 16.0431C2.55174 15.5921 2.55175 15.0385 2.55176 14.3677V5.63216C2.55175 4.96136 2.55174 4.40772 2.58859 3.95673C2.62686 3.48832 2.709 3.05773 2.91507 2.65329C3.23465 2.02608 3.74458 1.51614 4.37179 1.19657C4.77624 0.99049 5.20682 0.908353 5.67524 0.870082C6.12622 0.833235 6.67985 0.833243 7.35064 0.833253H10.0518C10.0647 0.833247 10.0713 0.833245 10.0911 0.834185C10.5026 0.853651 10.8645 1.21533 10.8841 1.62681Z" fill="#5C3CCD" stroke="none"/>
<path d="M16.2756 5.77181C16.1754 5.83325 16.0531 5.83325 15.8084 5.83325L13.2184 5.83321C12.9851 5.83321 12.8684 5.83321 12.7793 5.78779C12.7009 5.74784 12.6371 5.6841 12.5972 5.6057C12.5518 5.51657 12.5518 5.3999 12.5518 5.16655L12.5518 2.57662C12.5518 2.33193 12.5518 2.20959 12.6132 2.10937C12.7 1.96784 12.9044 1.88322 13.0658 1.92202C13.1801 1.9495 13.2598 2.02928 13.4192 2.18885L16.1962 4.96578C16.3557 5.12519 16.4355 5.2049 16.463 5.3192C16.5018 5.48062 16.4172 5.68504 16.2756 5.77181Z" fill="#5C3CCD" stroke="none"/>
</g>
</g>
</svg>
`;

export default FILE_ICON;
