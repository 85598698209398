import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIDataObject, Attribute, AttributeValue } from '../interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CompanyAttributesService {
  constructor(private http: HttpClient) {}

  getCompanyAttributes(
    companyId: number,
  ): Observable<APIDataObject<Attribute>> {
    return this.http.get<APIDataObject<Attribute>>(
      `/companies/${companyId}/attributes`,
    );
  }

  createCompanyAttribute(companyId: number, attributeLabel: string) {
    return this.http.post<Attribute>(`/companies/${companyId}/attributes`, {
      label: attributeLabel,
    });
  }

  updateCompanyAttribute(
    companyId: number,
    attributeLabel: string,
    attributeId: number,
  ) {
    return this.http.patch<Attribute>(
      `/companies/${companyId}/attributes/${attributeId}`,
      { label: attributeLabel },
    );
  }

  deleteCompanyAttribute(companyId: number, attributeId: number) {
    return this.http.delete<Pick<Attribute, 'attributeId'>>(
      `/companies/${companyId}/attributes/${attributeId}`,
    );
  }

  getCompanyAttributeValues(
    companyId: number,
    attributeId: number | string,
  ): Observable<APIDataObject<AttributeValue>> {
    return this.http.get<APIDataObject<AttributeValue>>(
      `/companies/${companyId}/attributes/${attributeId}/values`,
    );
  }

  createCompanyAttributeValues(
    companyId: number,
    attributeId: number,
    attributeValues: string[],
  ) {
    return this.http.post<AttributeValue[]>(
      `/companies/${companyId}/attributes/${attributeId}/values`,
      { values: attributeValues },
    );
  }

  updateCompanyAttributeValue(
    companyId: number,
    attributeId: number,
    attributeValueId: number,
    attributeValueLabel: string,
  ) {
    return this.http.patch<AttributeValue>(
      `/companies/${companyId}/attributes/${attributeId}/values/${attributeValueId}`,
      { value: attributeValueLabel },
    );
  }

  deleteCompanyAttributeValue(
    companyId: number,
    attributeId: number,
    attributeValueId: number,
  ) {
    return this.http.delete<{
      attributeValueId: number;
      attribute: Attribute | null;
    }>(
      `/companies/${companyId}/attributes/${attributeId}/values/${attributeValueId}`,
    );
  }
}
