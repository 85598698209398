import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const SystemAlertsActions = createActionGroup({
  source: 'System alerts',
  events: {
    'Show System Alert': props<{
      title: string;
      message?: string;
      systemAlertType: string;
    }>(),
    'Show Error System Alert': props<{
      title: string;
      message?: string;
    }>(),
    'Show Success System Alert': props<{
      title: string;
      message?: string;
    }>(),
    'Show Info System Alert': props<{
      title: string;
      message?: string;
    }>(),
    'Show Info Dialog': props<{
      title: string;
      message: string;
    }>(),
    'Show Confirmation Dialog': props<{
      title: string;
      message: string;
      actionName: string;
      cancelName?: string;
      modalType?: 'confirm' | 'remove';
      action: () => void;
      cancel?: () => void;
      close?: () => void;
    }>(),
    'Clear System Alert': emptyProps(),
  },
});
