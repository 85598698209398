<label class="switch">
  <input
    type="checkbox"
    [checked]="isChecked"
    [disabled]="disabled"
    (click)="toggle()"
  />
  <span class="slider"></span>
</label>

<ng-content></ng-content>
