const FACE_PROFILE = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_1042_2317)">
<path d="M6.66699 11.6667C6.66699 11.6667 7.91699 13.3333 10.0003 13.3333C12.0837 13.3333 13.3337 11.6667 13.3337 11.6667M14.167 7.7C13.8378 8.10417 13.3878 8.33334 12.917 8.33334C12.4462 8.33334 12.0087 8.10417 11.667 7.7M8.33366 7.7C8.00449 8.10417 7.55449 8.33334 7.08366 8.33334C6.61283 8.33334 6.17533 8.10417 5.83366 7.7M18.3337 10C18.3337 14.6024 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 14.6024 1.66699 10C1.66699 5.39763 5.39795 1.66667 10.0003 1.66667C14.6027 1.66667 18.3337 5.39763 18.3337 10Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</g>
<defs>
<clipPath id="clip0_1042_2317">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>`;

export default FACE_PROFILE;
