import { createReducer, on } from '@ngrx/store';
import { CompanyAttributesActions } from './company-attributes.actions';
import {
  Attribute,
  AttributeValue,
  AttributeWithValues,
} from '../../shared/interfaces';

export const companyAttributesFeatureKey = 'companyAttributes';

export interface State {
  isLoading: boolean;
  attributes: Attribute[];
  totalAttributes: number;
  attributeValues: AttributeValue[];
  attributesWithValues: AttributeWithValues[];
}

export const initialState: State = {
  isLoading: false,
  attributes: [],
  totalAttributes: 0,
  attributeValues: [],
  attributesWithValues: [],
};

export const companyAttributesReducer = createReducer(
  initialState,
  on(CompanyAttributesActions.loadingStateAttributes, (state, action) => ({
    ...state,
    isLoading: action.state,
  })),
  on(
    CompanyAttributesActions.getCompanyAttributesSuccess,
    (state, { attributes }) => ({ ...state, attributes }),
  ),
  on(
    CompanyAttributesActions.createAttributeSuccess,
    (state, { attribute }) => ({
      ...state,
      attributes: [...state.attributes, attribute].sort((a, b) =>
        a.label.localeCompare(b.label),
      ),
    }),
  ),
  on(
    CompanyAttributesActions.updateAttributeSuccess,
    (state, { attribute }) => {
      return {
        ...state,
        attributes: state.attributes
          .map((item) =>
            item.attributeId === attribute.attributeId ? attribute : item,
          )
          .sort((a, b) => a.label.localeCompare(b.label)),
      };
    },
  ),
  on(
    CompanyAttributesActions.deleteAttributeSuccess,
    (state, { attributeId }) => {
      return {
        ...state,
        attributes: state.attributes.filter(
          (item) => item.attributeId !== attributeId,
        ),
      };
    },
  ),
  on(
    CompanyAttributesActions.getCompanyAttributeValuesSuccess,
    (state, { attributeValues }) => ({ ...state, attributeValues }),
  ),
  on(
    CompanyAttributesActions.createAttributeValuesSuccess,
    (state, { attributeValues }) => ({
      ...state,
      attributeValues: [...state.attributeValues, ...attributeValues].sort(
        (a, b) => a.value.localeCompare(b.value),
      ),
    }),
  ),
  on(
    CompanyAttributesActions.updateAttributeValueSuccess,
    (state, { attributeValue }) => ({
      ...state,
      attributeValues: state.attributeValues
        .map((item) =>
          item.attributeValueId === attributeValue.attributeValueId
            ? attributeValue
            : item,
        )
        .sort((a, b) => a.value.localeCompare(b.value)),
    }),
  ),
  on(
    CompanyAttributesActions.deleteAttributeValueSuccess,
    (state, { attributeValueId, attribute }) => {
      return {
        ...state,
        attributeValues: state.attributeValues.filter(
          (item) => item.attributeValueId !== attributeValueId,
        ),
      };
    },
  ),
  on(
    CompanyAttributesActions.getCompanyAttributesWithValuesSuccess,
    (state, { attributes }) => ({ ...state, attributesWithValues: attributes }),
  ),
  on(
    CompanyAttributesActions.setCompanyAttributeTotal,
    (state, { totalAttributes }) => ({ ...state, totalAttributes }),
  ),
);
