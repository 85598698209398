import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { changeLanguage } from './language.actions';

@Injectable()
export class LanguageEffects {
  changeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeLanguage),
        tap((action) => localStorage.setItem('language', action.language)),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions) {}
}
