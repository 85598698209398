import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ChatEmployee } from '../interfaces/chat-employee.interface';
import { Store } from '@ngrx/store';
import { selectCurrentCompany } from '@state/user/user.reducer';
import { filter, switchMap } from 'rxjs/operators';
import { AttachedFile, AttachedFileUrls, AttachedPicture, ChatItem } from '@modules/chats/interfaces';

@Injectable({
  providedIn: 'root',
})
export class RestApiService {

  constructor(
    private http: HttpClient,
    private store: Store,
  ) { }

  getEmployeeList(options: { offset?: number; limit?: number, search: string }): Observable<ChatEmployee[]> {
    let params = new HttpParams();

    if (options.search) {
      params = params.append('search', options.search);
    }

    return this.store.select(selectCurrentCompany)
      .pipe(
        filter(Boolean),
        switchMap((currentCompany) => this.http.get<ChatEmployee[]>(`/companies/${currentCompany.id}/team-members`, { params })),
      );
  }

  getAttachedFiles(chatId: number): Observable<{ data: AttachedFile[] }> {
    return this.http.get<{ data: AttachedFile[] }>(`/api-chats/${chatId}/attached-files`);
  }

  getAttachedPictures(chatId: number): Observable<{ data: AttachedPicture[] }> {
    return this.http.get<{ data: AttachedPicture[] }>(`/api-chats/${chatId}/attached-pictures`);
  }

  uploadFiles(chatId: ChatItem['chatId'], body: FormData): Observable<AttachedFileUrls> {
    return this.http.post<AttachedFileUrls>(`/api-chats/${chatId}/attach-files`, body);
  }

}
