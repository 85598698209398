import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Company,
  CompanyPreferences,
  Preferences,
  User,
} from '../../state/user/user.model';
import { HttpClient } from '@angular/common/http';
import { ScoreType } from '@const/score-types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getMe(): Observable<User> {
    return this.http.get<User>('/users/me');
  }

  getMyCompanies(): Observable<{ data: Company[] }> {
    return this.http.get<{ data: Company[] }>('/managed-companies');
  }

  selectCompany(id: number): Observable<{ data: Company[] }> {
    return this.http.post<{ data: Company[] }>(`/companies/${id}/select`, {
      id,
    });
  }

  getCompanyPreferences(id: number): Observable<CompanyPreferences> {
    return this.http
      .get<{ portalCompanySettings: CompanyPreferences }>(
        `/companies/${id}/settings`,
      )
      .pipe(map(({ portalCompanySettings }) => portalCompanySettings));
  }

  setCompanyPreferences(
    id: number,
    preferences: Partial<CompanyPreferences>,
  ): Observable<CompanyPreferences> {
    return this.http
      .patch<{ portalCompanySettings: CompanyPreferences }>(
        `/companies/${id}/settings`,
        preferences,
      )
      .pipe(map(({ portalCompanySettings }) => portalCompanySettings));
  }

  getMyPreferences(): Observable<Preferences> {
    return this.http.get<Preferences>('/portal-preferences');
  }

  setMyPreferences(preferences: Partial<Preferences>): Observable<Preferences> {
    return this.http.patch<Preferences>('/portal-preferences', preferences);
  }

  updateScoreType(companyId: number, scoreType: ScoreType): Observable<ScoreType> {
    return this.http.patch<{ portalCompanySettings: CompanyPreferences }>(`/companies/${companyId}/settings`, { healthInsightsSwitcher: scoreType })
      .pipe(
        map(({ portalCompanySettings }) => portalCompanySettings?.healthInsightsSwitcher)
      );
  }

  refreshToken(
    refreshToken: string,
  ): Observable<{ accessToken: string; refreshToken: string }> {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      '/auth/refresh',
      {
        refreshToken,
      },
    );
  }
}
