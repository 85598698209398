import { createFeature, createReducer, on } from '@ngrx/store';
import { SystemAlertsActions } from './system-alerts.actions';

export const systemAlertsFeatureKey = 'system-alerts';

export interface State {
  title: string;
  message: string;
  systemAlertType: string;
}

export const initialState: State = {
  title: '',
  message: '',
  systemAlertType: '',
};

export const reducer = createReducer(
  initialState,
  on(SystemAlertsActions.showSystemAlert, (state) => state),
);

export const systemAlertsFeature = createFeature({
  name: systemAlertsFeatureKey,
  reducer,
});
