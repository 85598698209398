import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

declare module '@angular/forms' {
  interface FormControl {
    focused?: boolean;
  }

  interface AbstractControl {
    focused?: boolean;
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return control && control.invalid && control.dirty && !control.focused;
  }
}
