import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserActions } from '../../state/user/user.actions';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // TODO might be better to use Store for this
    const lang = localStorage.getItem('language');
    if (lang) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': lang,
        },
      });
    }
    return next.handle(request);
  }
}
