<img src="/assets/img/Error.svg" alt="" />
<h2 mat-dialog-title>
  <span [innerHTML]="data.title | translate"></span>
</h2>
<div mat-dialog-content>
  <div [innerHTML]="data.message | translate"></div>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-flat-button color="primary" cdkFocusInitial (click)="close()">
    {{ 'common.got-it' | translate }}
  </button>
</div>
