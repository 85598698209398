import { Injectable } from '@angular/core';
import {
  ChatEdit,
  ChatHistory,
  ChatHistoryRequest,
  ChatItem,
  ChatMessage,
  Member,
  NewMessage,
  PrivateChatCreationRequest,
  TeamChatCreationRequest,
} from '../interfaces';
import { SocketService } from './socket.service';
import { CHAT_TYPE, MESSAGE_STATUS, SOCKET_EVENTS } from '../enums/chat.enum';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  addNewMessage,
  clearChatStore,
  loadChat,
  loadChatsList,
  setCurrentCompanyId,
} from '../store/chat.actions';
import { ChatMessagesService } from './chat-messages.service';
import { ChatService } from './chat.service';
import { RestApiService } from '@modules/chats/services/rest-api.service';
import { ChatEmployee } from '@modules/chats/interfaces/chat-employee.interface';
import { selectCurrentCompany } from '@state/user/user.reducer';
import { Company } from '@state/user/user.model';
import { SystemAlertsActions } from '@state/system-alerts/system-alerts.actions';

/**
 * Service for controlling socket connections and events.
 * It handles socket connections, sending and receiving messages, managing chat lists,
 * handling exceptions, and other related operations.
 * */
@Injectable({
  providedIn: 'root',
})
export class SocketControllerService {
  constructor(
    private socket: SocketService,
    private store: Store,
    private restApiService: RestApiService,
    private chatMessagesService: ChatMessagesService,
    private chatService: ChatService,
  ) {}

  listenEvents(): Observable<void> {
    return merge(
      this.listenChatTriggerEvents(),
      this.listenGettingNewMessageEvent(),
      this.handleExceptions(),
      this.listenCompanyChanges(),
    ).pipe(map(() => void 0));
  }

  connectToChat(): Observable<void> {
    this.store.dispatch(clearChatStore());
    this.socket.connect();
    this.store.dispatch(loadChatsList());

    return this.listenEvents();
  }

  sendMessage(data: NewMessage): Observable<ChatMessage> {
    this.socket.emitData(SOCKET_EVENTS.MESSAGE_NEW, data);

    return this.socket.messageNew$;
  }

  getChatList(): Observable<ChatItem[]> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_LIST, {});

    return this.socket.chatList$;
  }

  deleteChat(
    chatId: ChatItem['chatId'],
  ): Observable<{ chatId: ChatItem['chatId'] }> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_DELETE, { chatId });

    return this.socket.chatDelete$;
  }

  listenGettingNewMessageEvent(): Observable<ChatMessage> {
    return this.socket.messageNew$.pipe(
      filter(Boolean),
      tap((message) => {
        this.store.dispatch(addNewMessage({ message }));
        this.chatMessagesService.scrollToBottom();

        if (this.chatService.activeChatItem?.chatId === message.chatId) {
          this.markMessagesAsRead(message.chatId, [message.messageId]);
        }
      }),
    );
  }

  listenCompanyChanges(): Observable<Company> {
    return this.store.select(selectCurrentCompany).pipe(
      filter(Boolean),
      tap(({ id }) => {
        this.store.dispatch(loadChatsList());
        this.store.dispatch(setCurrentCompanyId({ id }));
      }),
    );
  }

  handleExceptions(): Observable<void> {
    return this.socket.exception$.pipe(
      tap(({ message }) => {
        this.store.dispatch(
          SystemAlertsActions.showErrorSystemAlert({ title: message }),
        );
      }),
      map(() => void 0),
    );
  }

  listenChatTriggerEvents(): Observable<void> {
    return this.socket.chatTrigger$.pipe(filter(Boolean)).pipe(
      tap(({ chatId }) => this.store.dispatch(loadChat({ chatId }))),
      map(() => void 0),
    );
  }

  loadChat(chatId: ChatItem['chatId']): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT, { chatId });

    return this.socket.chat$;
  }

  createPrivateChat(data: PrivateChatCreationRequest): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_CREATE, data);

    return this.socket.chatCreate$.pipe(
      filter((chatItem) => chatItem.chatType === CHAT_TYPE.PRIVATE),
    );
  }

  createGroupChat(data: TeamChatCreationRequest): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_CREATE, data);

    return this.socket.chatCreate$.pipe(
      filter((chatItem) => chatItem.chatType === CHAT_TYPE.GROUP),
    );
  }

  editChat(body: ChatEdit): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_EDIT, body);

    return this.socket.chatEdit$;
  }

  getChatsHistory(
    chatHistoryRequest: ChatHistoryRequest,
  ): Observable<ChatHistory> {
    this.socket.emitData(SOCKET_EVENTS.CHAT_HISTORY, chatHistoryRequest);

    return this.socket.chatHistory$;
  }

  markMessageAsReadFromChatHistory({ chat, messages }: ChatHistory): void {
    const messageIds = new Set<ChatMessage['messageId']>();

    messages
      .filter((message) => message.status !== MESSAGE_STATUS.SEEN)
      .forEach((message) => messageIds.add(message.messageId));

    if (chat.newMessagesCount && !messageIds.has(chat.lastMessage.messageId)) {
      messageIds.add(chat.lastMessage.messageId);
    }

    if (messageIds.size) {
      this.markMessagesAsRead(chat.chatId, Array.from(messageIds));
    }
  }

  getEmployeeList(params: {
    offset?: number;
    limit?: number;
    search: string;
  }): Observable<ChatEmployee[]> {
    return this.restApiService.getEmployeeList(params);
  }

  removeChatMember(chatId: number, userId: number): Observable<ChatItem> {
    this.socket.emitData(SOCKET_EVENTS.MEMBER_DELETE, { chatId, userId });

    return this.socket.memberDelete$;
  }

  addChatMembers(chatId: number, userIds: number[]): Observable<Member[]> {
    this.socket.emitData(SOCKET_EVENTS.MEMBER_ADD, { chatId, userIds });

    return this.socket.membersAdd$;
  }

  deleteChatMessage(
    messageId: ChatMessage['messageId'],
  ): Observable<ChatMessage> {
    this.socket.emitData(SOCKET_EVENTS.MESSAGE_DELETE, { messageId });

    return this.socket.messageDelete$;
  }

  logChatMessage(messageId: ChatMessage['messageId']): Observable<ChatMessage> {
    this.socket.emitData(SOCKET_EVENTS.MESSAGE_LOG, {
      messageId,
    });

    return this.socket.messageLog$;
  }

  private markMessagesAsRead(chatId, messageIds: number[]): void {
    this.socket.emitData(SOCKET_EVENTS.MESSAGE_STATUS, { chatId, messageIds });
  }
}
