import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromChat from './chat.reducer';
import { ChatItem } from '../interfaces';

export const chatState = createFeatureSelector<fromChat.ChatState>(fromChat.chatFeatureKey);

export const chatList = createSelector(chatState, (state) => [...state.chatList].sort((chatA, chatB) => {
  const dateA = chatA.lastMessage.createdAt;
  const dateB = chatB.lastMessage.createdAt;

  return dateA > dateB ? -1 : 1;
}));

export const chatById = (chatId: ChatItem['chatId']) => createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === chatId));

export const employeeList = createSelector(chatState, (state) => state.employeeList.filter((employee) => employee.id !== state.userExternalId));

export const selectedEmployeeToCreateChat = createSelector(chatState, (state) => state.selectedEmployeeToCreateChat);

export const employeeListParams = createSelector(chatState, (state) => state.employeeListParams);

export const chatHistory = createSelector(chatState, (state) => state.chatHistory);

export const lastCreatedPrivateChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.lastCreatedPrivateChatId));

export const lastCreatedGroupChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.lastCreatedGroupChatId));

export const activePrivateChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.activePrivateChatId));

export const activeGroupChat = createSelector(chatState, (state) => state.chatList.find((chat) => chat.chatId === state.activeGroupChatId));

export const userExternalId = createSelector(chatState, (state) => state.userExternalId);

export const chatHistoryPending = createSelector(chatState, (state) => state.chatHistoryPending);

export const isChatInfoMenuOpened = createSelector(chatState, (state) => state.isChatInfoMenuOpened);

export const hasUnreadMessages = createSelector(chatState, (state) => !!state.chatList.find((chat) => !!chat.newMessagesCount));

export const attachedFiles = createSelector(chatState, (state) => state.attachedFiles);
export const attachedPictures = createSelector(chatState, (state) => state.attachedPictures);

export const selectCanCurrentUserLogMessage = createSelector(
  chatState,
  (state) => state.canCurrentUserLogMessage,
);
