import { Injectable } from '@angular/core';
import { catchError, filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, map, Observable, startWith, throwError } from 'rxjs';
import { AttachedFileUrls, ChatItem } from '../interfaces';
import { RestApiService } from '@modules/chats/services/rest-api.service';
import { SystemAlertsActions } from '@state/system-alerts/system-alerts.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly activeChatItemSubject$ =
    new BehaviorSubject<ChatItem | null>(null);

  constructor(
    private router: Router,
    private restApiService: RestApiService,
    private store: Store,
    private translate: TranslateService,
  ) {}

  get activeChatItem(): ChatItem {
    return this.activeChatItemSubject$.value;
  }

  setActiveChatItem(chatItem: ChatItem): void {
    this.activeChatItemSubject$.next(chatItem);
  }

  listenChatId(): Observable<number> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      startWith(this.router.url),
      map((currentUrl) => +currentUrl.split('/').reverse()[0]),
    );
  }

  getFileUrls({
    files,
    pictures,
  }: {
    files?: File[];
    pictures?: File[];
  }): Observable<AttachedFileUrls> {
    const chatId = this.activeChatItemSubject$.value.chatId;
    const formData: FormData = new FormData();

    if (files) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    if (pictures) {
      pictures.forEach((file) => {
        formData.append('pictures', file);
      });
    }

    return this.restApiService.uploadFiles(chatId, formData).pipe(
      catchError((error) => {
        if (error.error.code === 'invalid-upload-file-type') {
          this.store.dispatch(
            SystemAlertsActions.showErrorSystemAlert({
              title: this.translate.instant(
                'chats.your-file-could-not-be-uploaded',
              ),
            }),
          );
        }

        throw error;
      }),
    );
  }
}
