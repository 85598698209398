import { startWith } from 'rxjs/operators';
import {
  Directive,
  HostListener,
  OnInit,
  Self,
  DestroyRef,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: 'mat-select[appMatSelect]',
})
export class MatSelectDirective implements OnInit {
  constructor(
    @Self() private ngControl: NgControl,
    private destroyRef: DestroyRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}
  @HostListener('openedChange', ['$event'])
  onClick(opening: boolean) {
    const activeElement = document.activeElement as HTMLElement;
    if (!opening && activeElement?.tagName === 'MAT-SELECT') {
      activeElement?.blur();
    }
  }

  ngOnInit(): void {
    this.ngControl.valueChanges
      .pipe(
        startWith(this.ngControl.value),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        if (value) {
          this.renderer.addClass(
            this.elementRef.nativeElement,
            'mat-select--has-value',
          );
        } else {
          this.renderer.removeClass(
            this.elementRef.nativeElement,
            'mat-select--has-value',
          );
        }
      });
  }
}
