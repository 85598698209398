const MINUS_CIRCLE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<g clip-path="url(#clip0_3283_7944)">
<path d="M6.66666 10.1817H13.3333M18.3333 10.1817C18.3333 14.7841 14.6024 18.5151 9.99999 18.5151C5.39762 18.5151 1.66666 14.7841 1.66666 10.1817C1.66666 5.57935 5.39762 1.84839 9.99999 1.84839C14.6024 1.84839 18.3333 5.57935 18.3333 10.1817Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</g>
<defs>
<clipPath id="clip0_3283_7944">
<rect width="20" height="20" fill="white" transform="translate(0 0.181641)"/>
</clipPath>
</defs>
</svg>`;

export default MINUS_CIRCLE_ICON;
