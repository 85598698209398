import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiInterceptor } from './api.interceptor';
import { AuthInterceptor } from './auth.interceptor';
import { LanguageInterceptor } from './language.interceptor';
import { HasPortalRoleInterceptor } from './has-portal-role.interceptor';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HasPortalRoleInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
];
