import { createReducer, on } from '@ngrx/store';
import {
  addNewMessage,
  clearChatHistory,
  clearChatStore,
  createGroupChat,
  createGroupChatSuccess,
  createPrivateChat,
  createPrivateChatFailure,
  createPrivateChatSuccess,
  loadAttachedFilesSuccess,
  loadAttachedPicturesSuccess,
  loadChatHistoryPending,
  loadChatHistorySuccess,
  loadChatsListSuccess,
  loadChatSuccess,
  loadEmployeeListSuccess,
  markMessagesAsRead,
  resetEmployeeList,
  selectEmployeeToCreateChat,
  setCurrentCompanyId,
  setEmployeeListParams,
  setGroupActiveChatId,
  setPrivateActiveChatId,
  setUserExternalId,
  toggleChatInfoMenu,
} from './chat.actions';
import {
  AttachedFile,
  AttachedPicture,
  ChatHistory,
  ChatItem,
} from '../interfaces';
import { ChatEmployee } from '../interfaces/chat-employee.interface';

export const chatFeatureKey = 'chat';

export interface ChatState {
  chatList: ChatItem[];
  employeeList: ChatEmployee[];
  chatHistory: ChatHistory;
  chatHistoryPending: boolean;
  lastCreatedPrivateChatId: ChatItem['chatId'] | null;
  lastCreatedGroupChatId: ChatItem['chatId'] | null;
  activePrivateChatId: ChatItem['chatId'] | null;
  activeGroupChatId: ChatItem['chatId'] | null;
  userExternalId: number;
  isChatInfoMenuOpened: boolean;
  employeeListParams: { offset?: number; limit?: number; search: string };
  selectedEmployeeToCreateChat: ChatEmployee;
  currentCompanyId: number;
  attachedPictures: AttachedPicture[];
  attachedFiles: AttachedFile[];
  canCurrentUserLogMessage: boolean;
}

export const initialState: ChatState = {
  chatList: [],
  employeeList: [],
  chatHistory: null,
  chatHistoryPending: false,
  lastCreatedPrivateChatId: null,
  lastCreatedGroupChatId: null,
  activePrivateChatId: null,
  activeGroupChatId: null,
  userExternalId: null,
  isChatInfoMenuOpened: false,
  employeeListParams: {
    limit: null,
    offset: null,
    search: '',
  },
  selectedEmployeeToCreateChat: null,
  currentCompanyId: null,
  attachedPictures: [],
  attachedFiles: [],
  canCurrentUserLogMessage: false,
};

export const chatReducer = createReducer(
  initialState,
  on(loadChatsListSuccess, (state, { chatList }) => {
    return {
      ...state,
      chatList: chatList.filter(
        (chatItem) => chatItem.options.company.id === state.currentCompanyId,
      ),
    };
  }),

  on(loadEmployeeListSuccess, (state, { employeeList }) => ({
    ...state,
    employeeList,
  })),
  on(resetEmployeeList, (state) => ({
    ...state,
    employeeList: [],
  })),

  on(loadChatHistorySuccess, (state, { chatHistory }) => {
    const currentMessages = state.chatHistory?.messages || [];
    const newChatHistory: ChatHistory = {
      ...chatHistory,
      messages: [
        ...currentMessages,
        ...chatHistory?.messages.filter(
          (message) =>
            !currentMessages.find(
              (currentMessages) =>
                currentMessages.messageId === message.messageId,
            ),
        ),
      ],
    };

    return {
      ...state,
      chatHistory: newChatHistory,
      chatHistoryPending: false,
    };
  }),

  on(createGroupChat, (state) => ({ ...state, lastCreatedGroupChatId: null })),

  on(createGroupChatSuccess, (state, { chatItem }) => {
    const isChatAlreadyExist = state.chatList.find(
      (chat) => chat.chatId === chatItem.chatId,
    );

    if (isChatAlreadyExist) {
      return state;
    }

    return {
      ...state,
      lastCreatedGroupChatId: chatItem.chatId,
      chatList: [...state.chatList, chatItem],
    };
  }),

  on(clearChatHistory, (state) => ({ ...state, chatHistory: null })),

  on(loadChatHistoryPending, (state) => ({
    ...state,
    chatHistoryPending: true,
  })),

  on(createPrivateChat, (state) => ({
    ...state,
    lastCreatedPrivateChatId: null,
  })),

  on(createPrivateChatSuccess, (state, { chatItem }) => {
    const isChatAlreadyExist = state.chatList.find(
      (chat) => chat.chatId === chatItem.chatId,
    );

    if (isChatAlreadyExist) {
      return state;
    }

    return {
      ...state,
      lastCreatedPrivateChatId: chatItem.chatId,
      chatList: [...state.chatList, chatItem],
    };
  }),

  on(createPrivateChatFailure, (state) => ({
    ...state,
    lastCreatedPrivateChatId: null,
  })),

  on(setPrivateActiveChatId, (state, { id }) => ({
    ...state,
    activePrivateChatId: id,
  })),
  on(setGroupActiveChatId, (state, { id }) => ({
    ...state,
    activeGroupChatId: id,
  })),

  on(addNewMessage, (state, { message }) => {
    const chatHistory = state.chatHistory?.messages || [];
    const messageAlreadyExist = !!chatHistory.find(
      (historyMessage) => historyMessage?.messageId === message?.messageId,
    );
    const isActiveChat =
      message.chatId === state.activePrivateChatId ||
      message.chatId === state.activeGroupChatId;
    const updatedMessages = isActiveChat
      ? [...chatHistory, message]
      : chatHistory;

    return {
      ...state,
      chatList: state.chatList.map((chat) =>
        chat.chatId === message.chatId
          ? {
              ...chat,
              lastMessage: message,
              newMessagesCount: isActiveChat
                ? chat.newMessagesCount
                : chat.newMessagesCount + 1,
            }
          : chat,
      ),
      chatHistory: {
        ...state.chatHistory,
        messages: messageAlreadyExist ? chatHistory : updatedMessages,
      },
    };
  }),

  on(loadChatSuccess, (state, { chat }) => ({
    ...state,
    chatList: state.chatList.map((chatItem) =>
      chatItem.chatId === chat.chatId ? chat : chatItem,
    ),
    canCurrentUserLogMessage: chat.canCurrentUserLogMessage,
  })),

  on(setCurrentCompanyId, (state, { id }) => ({
    ...state,
    currentCompanyId: id,
  })),

  on(setUserExternalId, (state, { userExternalId }) => ({
    ...state,
    userExternalId,
  })),

  on(clearChatStore, () => initialState),
  on(toggleChatInfoMenu, (state, { value }) => ({
    ...state,
    isChatInfoMenuOpened: value,
  })),
  on(setEmployeeListParams, (state, { limit, offset, search }) => ({
    ...state,
    employeeListParams: {
      limit,
      offset,
      search,
    },
  })),
  on(selectEmployeeToCreateChat, (state, { employee }) => ({
    ...state,
    selectedEmployeeToCreateChat: employee,
  })),

  on(loadAttachedFilesSuccess, (state, { attachedFiles }) => ({
    ...state,
    attachedFiles,
  })),
  on(loadAttachedPicturesSuccess, (state, { attachedPictures }) => ({
    ...state,
    attachedPictures,
  })),
  on(markMessagesAsRead, (state, { chatId }) => ({
    ...state,
    chatList: state.chatList.map((chat) => {
      return chat.chatId === chatId ? { ...chat, newMessagesCount: 0 } : chat;
    }),
  })),
);
