import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Company, CompanyPreferences, Preferences, StartScreens, User } from './user.model';
import { ScoreType } from '@const/score-types';

export const UserActions = createActionGroup({
  source: 'User/API',
  events: {
    'Login User': props<{ accessToken: string; refreshToken: string }>(),
    'Logout User': props<{ clearTokensOnly?: boolean }>(),
    'Add User': emptyProps(),
    'Add User Success': props<{ user: User }>(),
    'Update User': props<{ user: Update<User> }>(),
    'Delete User': props<{ id: string }>(),
    'Load User Companies': emptyProps(),
    'Load User Companies Success': props<{ companies: Company[] }>(),
    'Set Current Company': props<{ company: Company }>(),
    'Select Company': props<Company>(),
    'Load User Preferences': emptyProps(),
    'Load User Preferences Success': props<{ preferences: Preferences }>(),
    'Update User Language Preferences': props<Partial<Preferences>>(),
    'Update User Start Screens Preferences': props<{ page: StartScreens }>(),
    'Refresh Token': emptyProps(),
    'Refresh Token success': props<{
      accessToken: string;
      refreshToken: string;
    }>(),
    'Load Company Preferences': props<{ companyId: number }>(),
    'Load Company Preferences Success': props<{
      preferences: CompanyPreferences;
    }>(),
    'Update Company Preferences': props<{
      preferences: Partial<CompanyPreferences>;
    }>(),
    'Update Score Type': props<{ scoreType: ScoreType }>(),
    'Update Score Type Success': props<{ scoreType: ScoreType }>(),
  },
});
