// notice that this icon doesn't have a stroke color

const LOGOUT_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
  <g clip-path="url(#clip0_3159_431)">
    <path d="M15.0521 7.15763L18.3854 10.491M18.3854 10.491L15.0521 13.8243M18.3854 10.491H7.55208M12.5521 3.99433C11.4898 3.3562 10.2565 2.99097 8.94097 2.99097C4.95225 2.99097 1.71875 6.34883 1.71875 10.491C1.71875 14.6331 4.95225 17.991 8.94097 17.991C10.2565 17.991 11.4898 17.6257 12.5521 16.9876" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
  </g>
  <defs>
    <clipPath id="clip0_3159_431">
      <rect width="20" height="20" fill="white" transform="translate(0.0517578 0.490967)"/>
    </clipPath>
  </defs>
</svg>
`;

export default LOGOUT_ICON;
