import { filter } from 'rxjs/operators';
import { CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { PermissionsService } from './permissions.service';
import { map } from 'rxjs';

@Injectable()
export class RoutePermissionService {
  constructor(private permissions: PermissionsService) {}

  canActivate(licensePermissions: string[], permissions: string[]): boolean {
    let licensePermission = true;
    let generalPermission = true;
    if (permissions?.length > 0) {
      generalPermission = this.permissions.checkPermissionStrict(permissions);
    }
    if (licensePermissions?.length > 0) {
      licensePermission =
        this.permissions.checkLicensePermissions(licensePermissions);
    }

    return licensePermission && generalPermission;
  }
}

/**
 * @description This function is used to check if the user has the required permissions to access a route.
 * @example
 * {
      path: 'chats',
      loadChildren: () =>
        import('../chats/chats.module').then((m) => m.ChatsModule),
      canActivate: [canActivateGeneral],
      runGuardsAndResolvers: 'always',
      data: {
        licensePermissions: ['CHATS.VIEW'],
        permissions: ['CHATS.VIEW'],
      },
    },
 * @param route
 * @returns Observable<boolean>
 */
export const canActivateGeneral: CanActivateFn = (route) => {
  const service = inject(RoutePermissionService);
  const router = inject(Router);
  return inject(PermissionsService).role$.pipe(
    filter((role) => !!role),
    map(() => {
      const isAllowed = service.canActivate(
        route.data.licensePermissions,
        route.data.permissions,
      );

      if (!isAllowed) {
        router.navigate(['/']);
      }
      return isAllowed;
    }),
  );
};
