import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  constructor() { }

  getEventSource(url: string): EventSource {
    return new EventSource(
      `${environment.apiUrl}${environment.apiVersion}${url}`,
    );
  }
}
