export const API_ROUTES = {
  AUTH: {
    CREATE_SESSION: '/auth/login/existing-session',
    SSE: (sessionId: string) => `/auth/login/existing-session/${sessionId}/sse`,
    LOGOUT: '/auth/logout',
  },
  ROLES: {
    GET_ROLES: (companyId: number) => `/companies/${companyId}/employee-portal-roles`,
  },
  USERS: {
    ME: '/users/me',
  },
};
