import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('portal/companies')) {
      request = request.clone({
        url: `${environment.healthApiUrl}${environment.healthApiVersion}/api${request.url}`,
      });
    } else if (request.url.includes('api-chats')) {
      const headers = new HttpHeaders().set('x-chat-authorization', 'nano_secret');

      request = request.clone({
        url: `${environment.SOCKET_URL}${request.url}`,
        headers,
      });
    } else if (!request.url.includes('assets')) {
      request = request.clone({
        url: `${environment.apiUrl}${environment.apiVersion}${request.url}`,
      });
    }

    return next.handle(request);
  }
}
