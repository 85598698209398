<h2 mat-dialog-title>
  <span>{{ data.title }}</span>
</h2>
<div mat-dialog-content>
  <div class="message">
    {{ data.message }}
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="primary" (click)="close()">
    {{ 'common.cancel' | translate }}
  </button>
  <button mat-flat-button color="warn" cdkFocusInitial (click)="callAction()">
    {{ data.actionName }}
  </button>
</div>
