const FILE_GRAPH = `
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
<g clip-path="url(#clip0_4903_1345)">
<path d="M12.3736 2.62061V6.06272C12.3736 6.52943 12.3736 6.76279 12.4644 6.94105C12.5443 7.09785 12.6718 7.22533 12.8286 7.30523C13.0068 7.39606 13.2402 7.39606 13.7069 7.39606H17.149M7.37358 13.2293V15.7293M14.0402 11.5627V15.7293M10.7069 9.47933V15.7293M17.3736 9.05285V15.0627C17.3736 16.4628 17.3736 17.1629 17.1011 17.6976C16.8614 18.168 16.479 18.5505 16.0086 18.7902C15.4738 19.0627 14.7737 19.0627 13.3736 19.0627H8.04025C6.64011 19.0627 5.94005 19.0627 5.40527 18.7902C4.93486 18.5505 4.55241 18.168 4.31273 17.6976C4.04025 17.1629 4.04025 16.4628 4.04025 15.0627V6.396C4.04025 4.99586 4.04025 4.2958 4.31273 3.76102C4.55241 3.29061 4.93486 2.90816 5.40527 2.66848C5.94005 2.396 6.64011 2.396 8.04025 2.396H10.7167C11.3282 2.396 11.6339 2.396 11.9217 2.46507C12.1767 2.52631 12.4206 2.62732 12.6443 2.7644C12.8966 2.919 13.1128 3.13519 13.5452 3.56757L16.202 6.22442C16.6344 6.6568 16.8506 6.87299 17.0052 7.12528C17.1423 7.34896 17.2433 7.59283 17.3045 7.84792C17.3736 8.13564 17.3736 8.44137 17.3736 9.05285Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</g>
<defs>
<clipPath id="clip0_4903_1345">
<rect width="20" height="20" fill="white" transform="translate(0.706909 0.729492)"/>
</clipPath>
</defs>
</svg>
`;

export default FILE_GRAPH;
