const X_CIRCLE_CLOSE = `
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
  <g clip-path="url(#clip0_8680_4190)">
    <path d="M11.25 7.70154L6.75 12.2015M6.75 7.70154L11.25 12.2015M16.5 9.95154C16.5 14.0937 13.1421 17.4515 9 17.4515C4.85786 17.4515 1.5 14.0937 1.5 9.95154C1.5 5.8094 4.85786 2.45154 9 2.45154C13.1421 2.45154 16.5 5.8094 16.5 9.95154Z" stroke="#5C3CCD" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_8680_4190">
      <rect width="18" height="18" fill="white" transform="translate(0 0.951538)"/>
    </clipPath>
  </defs>
</svg>`;

export default X_CIRCLE_CLOSE;
