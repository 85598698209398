import { Component, Host, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-system-alert',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './system-alert.component.html',
  styleUrls: ['./system-alert.component.scss'],
})
export class SystemAlertComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      title: string;
      message: string;
      actions: string;
      type: 'success' | 'error' | 'info';
    } = {
      title: '',
      message: '',
      actions: '',
      type: 'success',
    },
    public snackBarRef: MatSnackBarRef<SystemAlertComponent>,
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.snackBarRef.dismiss();
  }
}
