import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsDirective } from './permission.directive';
import { PermissionsService } from './permissions.service';
import { RoutePermissionService } from './route-permission.service';

@NgModule({
  declarations: [PermissionsDirective],
  imports: [CommonModule],
  providers: [PermissionsService, RoutePermissionService],
  exports: [PermissionsDirective],
})
export class PermissionsModule {}
