import { Injectable } from '@angular/core';
import { CHAT_HISTORY_TYPE } from '../enums/chat.enum';
import { AttachedFileUrls, ChatHistoryRequest, ChatItem, LoadedFile, NewMessage } from '../interfaces';
import { Store } from '@ngrx/store';
import { loadChatHistory, sendMessage } from '../store/chat.actions';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { chatHistory } from '../store/chat.selectors';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ChatService  } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class ChatMessagesService {
  private readonly messageLimit = 50 as const;
  private readonly scrollToBottomSubject$ = new Subject<void>();
  private readonly selectedFilesSubject$ = new BehaviorSubject<File[]>([]);
  private readonly selectedImagesSubject$ = new BehaviorSubject<File[]>([]);

  constructor(
    private store: Store,
    private chatService: ChatService,
  ) { }

  loadMessages(chatItem: ChatItem): void {
    const chatHistoryRequest: ChatHistoryRequest = {
      chatId: chatItem.chatId,
      type: CHAT_HISTORY_TYPE.BEFORE,
      messageId: chatItem.lastMessage.messageId,
      limit: this.messageLimit,
    };

    this.store.dispatch(loadChatHistory({ chatHistoryRequest }));
  }

  loadOldMessages(chatItem: ChatItem): Observable<void> {
    return this.store.select(chatHistory)
      .pipe(
        take(1),
        tap((chatHistory) => {
          const chatHistoryRequest: ChatHistoryRequest = {
            chatId: chatItem.chatId,
            type: CHAT_HISTORY_TYPE.AFTER,
            messageId: 0,
            limit: this.messageLimit,
            cursor: chatHistory?.cursor,
          };

          this.store.dispatch(loadChatHistory({ chatHistoryRequest }));
        }),
        map(() => void 0),
      );
  }

  getScrollToBottomEvent(): Observable<void> {
    return this.scrollToBottomSubject$.asObservable();
  }

  scrollToBottom(): void {
    this.scrollToBottomSubject$.next();
  }

  addFiles(files: File[]): void {
    this.selectedFilesSubject$.next(files);
  }

  getFiles(): Observable<File[]> {
    return this.selectedFilesSubject$.asObservable();
  }

  addImages(images: File[]): void {
    const currentImages = this.selectedImagesSubject$.value;
    const nextImages = [...currentImages, ...images].slice(0, 10);

    this.selectedImagesSubject$.next(nextImages);
  }

  getImageUrls(): Observable<string[]> {
    return this.selectedImagesSubject$.asObservable()
      .pipe(
        switchMap((images) => this.getPreviewImages(images))
      );
  }

  getPdfFiles(): Observable<LoadedFile[]> {
    return this.selectedFilesSubject$.asObservable()
      .pipe(
        map((files) => files.map((file) => this.convertToPdfFormat(file))),
      );
  }

  removeImageByIndex(index: number): void {
    const filteredImages = this.selectedImagesSubject$.value.filter((image, imageIndex) => imageIndex !== index);

    this.selectedImagesSubject$.next(filteredImages);
  }

  removeFileByIndex(index: number): void {
    const filteredFiles = this.selectedFilesSubject$.value.filter((file, fileIndex) => fileIndex !== index);

    this.selectedFilesSubject$.next(filteredFiles);
  }

  sendMessage(chatItem: ChatItem, messageText: string): Observable<unknown> {
    const selectedImages = this.selectedImagesSubject$.value;
    const selectedFiles = this.selectedFilesSubject$.value;

    if (!messageText && !selectedImages.length && !selectedFiles.length) {
      return of(null);
    }

    const urls$ = this.chatService.getFileUrls({ files: selectedFiles, pictures: selectedImages });

    this.selectedImagesSubject$.next([]);
    this.selectedFilesSubject$.next([]);

    return ((selectedFiles.length || selectedImages.length) ? urls$ : of({}))
      .pipe(
        tap((attachedFileUrls: AttachedFileUrls) => {
          const message: NewMessage = {
            frameId: Date.now().toString(),
            chatId: chatItem.chatId,
            text: messageText,
            options: {
              company: {
                id: chatItem.options?.company?.id,
              },
              ...(!!attachedFileUrls?.pictures?.length && { attachedPictures: attachedFileUrls.pictures.map(({ attachedPicture, attachedPicturePreview }, index) => {
                return {
                  originalName: selectedImages[index].name,
                  attachedPicture,
                  attachedPicturePreview,
                  size: selectedImages[index].size,
                };
                })}),
              ...(!!attachedFileUrls?.files?.length && { attachedFiles: attachedFileUrls.files.map(({ file }, index) => {
                return {
                  file,
                  originalName: selectedFiles[index].name,
                  size: selectedFiles[index].size,
                  mimetype: selectedFiles[index].type,
                };
              })}),
            },
          };

          this.store.dispatch(sendMessage({ message }));
        }),
      );
  }

  private convertToPdfFormat(file: File): LoadedFile {
    return {
      name: file.name,
      size: file.size,
      file: URL.createObjectURL(file),
    }
  }

  private getPreviewImages(images: File[]): Observable<string[]> {
    const imageReadObservables: Observable<string>[] = [];

    images.forEach((image) => {
      const reader = new FileReader();

      const readerObservable = new Observable<string>((observer) => {
        reader.onload = (readerEvent) => {
          observer.next(readerEvent.target.result as string);
          observer.complete();
        };

        reader.readAsDataURL(image);
      });

      imageReadObservables.push(readerObservable);
    });

    return imageReadObservables.length ? forkJoin(imageReadObservables) : of([]);
  }
}
