const GROUP_SYMBOL = `
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0263 12.3974C15.141 11.9517 15.5953 11.6833 16.041 11.7981C17.838 12.2606 19.1667 13.8908 19.1667 15.8334V17.5001C19.1667 17.9603 18.7936 18.3334 18.3333 18.3334C17.8731 18.3334 17.5 17.9603 17.5 17.5001V15.8334C17.5 14.6697 16.7041 13.6897 15.6256 13.4121C15.1799 13.2974 14.9116 12.8431 15.0263 12.3974Z" stroke="none" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.1442 2.4297C12.3169 2.00309 12.8027 1.79724 13.2293 1.96993C14.7548 2.58742 15.8333 4.08347 15.8333 5.83342C15.8333 7.58336 14.7548 9.07941 13.2293 9.6969C12.8027 9.86959 12.3169 9.66374 12.1442 9.23713C11.9715 8.81052 12.1774 8.32469 12.604 8.152C13.5217 7.78051 14.1667 6.88128 14.1667 5.83342C14.1667 4.78555 13.5217 3.88632 12.604 3.51483C12.1774 3.34214 11.9715 2.85631 12.1442 2.4297Z" stroke="none" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.98245 11.6667C7.27193 11.6671 8.56141 11.6671 9.85088 11.6667C10.7597 11.6664 11.3799 11.6662 11.9117 11.8087C13.3496 12.194 14.4727 13.3171 14.858 14.755C15.0499 15.4711 14.9943 16.2364 15.0001 16.9702C15.0009 17.0592 15.0023 17.2394 14.9574 17.4069C14.8418 17.8383 14.5049 18.1752 14.0735 18.2908C13.906 18.3357 13.7258 18.3343 13.6367 18.3336C9.82424 18.3031 6.0091 18.3031 2.19659 18.3336C2.1075 18.3343 1.92732 18.3357 1.75981 18.2908C1.32844 18.1752 0.99151 17.8383 0.875926 17.4069C0.831041 17.2394 0.832478 17.0592 0.833188 16.9702C0.839023 16.2382 0.783737 15.47 0.975309 14.755C1.36059 13.3171 2.4837 12.194 3.92159 11.8087C4.45343 11.6662 5.0736 11.6664 5.98245 11.6667Z" stroke="none" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.83342C3.75 3.53223 5.61548 1.66675 7.91667 1.66675C10.2179 1.66675 12.0833 3.53223 12.0833 5.83342C12.0833 8.1346 10.2179 10.0001 7.91667 10.0001C5.61548 10.0001 3.75 8.1346 3.75 5.83342Z" stroke="none" />
</svg>
`;

export default GROUP_SYMBOL;
