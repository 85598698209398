import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const GeneralStateActions = createActionGroup({
  source: 'GeneralState/API',
  events: {
    'Toggle Sidenav': emptyProps(),
    'Set Sidenav': props<{ isShow: boolean }>(),
    'Toggle Header': emptyProps(),
    'Clear GeneralStates': emptyProps(),
    'Mark Unsaved Changes': props<{ unsavedChanges: boolean }>(),
    'Leaving Before Saving Warning': props<{ nextUrl?: string }>(),
    'Leaving Before Saving Warning Dismissed': emptyProps(),
    'Leaving Before Saving Warning Confirmed': emptyProps(),
  },
});
