const MY_TEAM_ICON = `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_14116_29088)">
<path d="M18.5959 21.1295C18.5959 19.4314 18.5959 18.5823 18.3185 17.9126C17.9486 17.0196 17.2391 15.399 16.3461 15.0291C15.6764 14.7517 14.8273 14.7517 13.1292 14.7517H11.307C10.52 14.7517 9.67098 14.7517 9.00124 15.0291C8.10825 15.399 7.39877 17.0196 7.02888 17.9126C6.75146 18.5823 6.75146 19.4314 6.75146 21.1295M16.3181 8.37393C16.3181 10.3867 14.6865 12.0184 12.6737 12.0184C10.6609 12.0184 9.02924 10.3867 9.02924 8.37393C9.02924 6.36116 10.6609 4.72949 12.6737 4.72949C14.6865 4.72949 16.3181 6.36116 16.3181 8.37393Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M4.62555 13.2332C5.9674 13.2332 7.05518 12.1454 7.05518 10.8035C7.05518 9.46168 5.9674 8.3739 4.62555 8.3739C3.2837 8.3739 2.19592 9.46168 2.19592 10.8035C2.19592 12.1454 3.2837 13.2332 4.62555 13.2332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" />
<path d="M21.0256 13.2332C19.6837 13.2332 18.5959 12.1454 18.5959 10.8035C18.5959 9.46168 19.6837 8.3739 21.0256 8.3739C22.3674 8.3739 23.4552 9.46168 23.4552 10.8035C23.4552 12.1454 22.3674 13.2332 21.0256 13.2332Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none" />
<path d="M22.544 21.0147V19.1925C22.544 17.4943 22.2937 16.0674 20.7218 15.6628" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
<path d="M3.10698 21.0147V19.1925C3.10698 17.4943 3.35733 16.0674 4.9292 15.6628" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</g>
<defs>
<clipPath id="clip0_14116_29088">
<rect width="24" height="24" fill="white" transform="translate(0.492188 0.729492)"/>
</clipPath>
</defs>
</svg>
`;

export default MY_TEAM_ICON;
