import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, switchMap, map, catchError, tap } from 'rxjs/operators';
import { CompanyAttributesService } from '../../shared/services/company-attributes.service';
import { CompanyAttributesActions } from './company-attributes.actions';
import { Store } from '@ngrx/store';
import { attributes } from './company-attributes.selectors';
import { forkJoin, throwError, take } from 'rxjs';
import { SystemAlertsActions } from '@state/system-alerts/system-alerts.actions';
import { Attribute } from '@shared/interfaces';
import { ErrorCodes } from '@const/error-codes';

@Injectable()
export class CompanyAttributesEffects {
  constructor(
    private actions$: Actions,
    private companyAttributesService: CompanyAttributesService,
    private store: Store,
    private t: TranslateService,
  ) {}

  loadAttributeValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyAttributesActions.getCompanyAttributesWithValues),
      switchMap(({ companyId }) =>
        this.store.select(attributes).pipe(
          take(1),
          switchMap((attributes) =>
            forkJoin(
              attributes.map((attribute) =>
                this.companyAttributesService
                  .getCompanyAttributeValues(companyId, attribute.attributeId)
                  .pipe(
                    map((res) => {
                      return {
                        ...attribute,
                        attributeValues: res.data,
                      };
                    }),
                  ),
              ),
            ),
          ),
          concatMap((attributes) => {
            return [
              CompanyAttributesActions.getCompanyAttributesWithValuesSuccess({
                attributes,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  getCompanyAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.getCompanyAttributes),
      switchMap(({ companyId }) =>
        this.companyAttributesService.getCompanyAttributes(companyId),
      ),
      concatMap(({ data, total }) => [
        CompanyAttributesActions.getCompanyAttributesSuccess({
          attributes: data,
        }),
        CompanyAttributesActions.setCompanyAttributeTotal({
          totalAttributes: total,
        }),
      ]),
    );
  });

  createCompanyAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.createAttribute),
      switchMap(({ companyId, attributeLabel }) =>
        this.companyAttributesService.createCompanyAttribute(
          companyId,
          attributeLabel,
        ),
      ),
      catchError(({ error }) => {
        if (error.code !== ErrorCodes.ATTRIBUTE_NAME_ALREADY_EXISTS) {
          this.store.dispatch(
            SystemAlertsActions.showErrorSystemAlert({
              title: error?.title,
            }),
          );
        }
        this.store.dispatch(
          CompanyAttributesActions.createAttributeFailure({ error }),
        );
        this.store.dispatch(
          CompanyAttributesActions.loadingStateAttributes({ state: false }),
        );
        return throwError(() => error);
      }),
      concatMap((attribute: Attribute) => {
        return [
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant('attributes.create-attribute-message'),
          }),
          CompanyAttributesActions.createAttributeSuccess({ attribute }),
          CompanyAttributesActions.loadingStateAttributes({ state: false }),
        ];
      }),
    );
  });

  updateCompanyAttribute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.updateAttribute),
      switchMap(({ companyId, attributeLabel, attributeId }) =>
        this.companyAttributesService.updateCompanyAttribute(
          companyId,
          attributeLabel,
          attributeId,
        ),
      ),
      catchError(({ error }) => {
        if (error.code !== ErrorCodes.ATTRIBUTE_NAME_ALREADY_EXISTS) {
          this.store.dispatch(
            SystemAlertsActions.showErrorSystemAlert({
              title: error?.title,
            }),
          );
        }
        this.store.dispatch(
          CompanyAttributesActions.updateAttributeFailure({ error }),
        );
        this.store.dispatch(
          CompanyAttributesActions.loadingStateAttributes({ state: false }),
        );
        return throwError(() => error);
      }),
      concatMap((attribute) => {
        return [
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant('attributes.update-attribute-message'),
          }),
          CompanyAttributesActions.updateAttributeSuccess({ attribute }),
          CompanyAttributesActions.loadingStateAttributes({ state: false }),
        ];
      }),
    );
  });

  deleteCompanyAttribute$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.deleteAttribute),
      switchMap(({ companyId, attributeId }) =>
        this.companyAttributesService.deleteCompanyAttribute(
          companyId,
          attributeId,
        ),
      ),
      catchError((error) => {
        this.store.dispatch(
          SystemAlertsActions.showErrorSystemAlert({
            title: error.error?.title,
          }),
        );
        return throwError(() => error);
      }),
      concatMap(({ attributeId }) => {
        return [
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant('attributes.delete-attribute-message'),
          }),
          CompanyAttributesActions.deleteAttributeSuccess({
            attributeId,
          }),
        ];
      }),
    );
  });

  createCompanyAttributeValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.createAttributeValues),
      switchMap(({ companyId, attributeId, attributeValues }) =>
        this.companyAttributesService.createCompanyAttributeValues(
          companyId,
          attributeId,
          attributeValues,
        ),
      ),
      catchError((error) => {
        this.store.dispatch(
          SystemAlertsActions.showErrorSystemAlert({
            title: error.error?.title,
          }),
        );
        return throwError(() => error);
      }),
      concatMap((attributeValues) => {
        return [
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant(
              'attributes.create-attribute-category-message',
              { n: attributeValues.length },
            ),
          }),
          CompanyAttributesActions.createAttributeValuesSuccess({
            attributeValues,
          }),
        ];
      }),
    );
  });

  updateCompanyAttributeValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.updateAttributeValue),
      switchMap(
        ({ companyId, attributeId, attributeValueId, attributeValueLabel }) =>
          this.companyAttributesService.updateCompanyAttributeValue(
            companyId,
            attributeId,
            attributeValueId,
            attributeValueLabel,
          ),
      ),
      catchError(({ error }) => {
        if (error.code !== ErrorCodes.ATTRIBUTE_VALUE_NAME_ALREADY_EXISTS) {
          this.store.dispatch(
            SystemAlertsActions.showErrorSystemAlert({
              title: error?.title,
            }),
          );
        }
        this.store.dispatch(
          CompanyAttributesActions.updateAttributeValueFailure({ error }),
        );
        return throwError(() => error);
      }),
      concatMap((attributeValue) => {
        return [
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant(
              'attributes.update-attribute-category-message',
            ),
          }),
          CompanyAttributesActions.updateAttributeValueSuccess({
            attributeValue,
          }),
        ];
      }),
    );
  });

  deleteCompanyAttributeValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.deleteAttributeValue),
      switchMap(({ companyId, attributeId, attributeValueId }) =>
        this.companyAttributesService.deleteCompanyAttributeValue(
          companyId,
          attributeId,
          attributeValueId,
        ),
      ),
      catchError((error) => {
        this.store.dispatch(
          SystemAlertsActions.showErrorSystemAlert({
            title: error.error?.title,
          }),
        );
        return throwError(() => error);
      }),
      concatMap(({ attributeValueId, attribute }) => {
        return [
          SystemAlertsActions.showSuccessSystemAlert({
            title: this.t.instant(
              'attributes.delete-attribute-category-message',
            ),
          }),
          CompanyAttributesActions.deleteAttributeValueSuccess({
            attributeValueId,
            attribute,
          }),
          attribute
            ? CompanyAttributesActions.updateAttributeSuccess({ attribute })
            : CompanyAttributesActions.deleteAttributeSuccess({
                attributeId: attribute.attributeId,
              }),
        ];
      }),
    );
  });

  getCompanyAttributeValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyAttributesActions.getCompanyAttributeValues),
      switchMap(({ companyId, attributeId }) =>
        this.companyAttributesService.getCompanyAttributeValues(
          companyId,
          attributeId,
        ),
      ),
      concatMap(({ data }) => [
        CompanyAttributesActions.getCompanyAttributeValuesSuccess({
          attributeValues: data,
        }),
      ]),
    );
  });
}
