import { createReducer, on } from '@ngrx/store';
import * as LanguageActions from './language.actions';

export interface State {
  language: string;
}

const initialState: State = {
  language: localStorage.getItem('language') || 'no',
};

export const languageReducer = createReducer(
  initialState,
  on(LanguageActions.changeLanguage, (state, { language }) => ({
    ...state,
    language,
  })),
);
