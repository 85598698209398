import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CompanyAttributesEffects } from './company-attributes.effects';
import { StoreModule } from '@ngrx/store';
import { companyAttributesFeatureKey, companyAttributesReducer } from './company-attributes.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([CompanyAttributesEffects]),
    StoreModule.forFeature(companyAttributesFeatureKey, companyAttributesReducer)
  ]
})
export class CompanyAttributesModule { }
