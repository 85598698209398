import { map } from 'rxjs';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  return inject(Store)
    .select('user')
    .pipe(
      map((user) => {
        if (!user.accessToken) {
          return router.createUrlTree(['/login']);
        }
        return true;
      }),
    );
};
