import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PermissionsService } from './permissions.service';
import {
  DestroyRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

/**
 * @description This directive is used to show or hide elements based on the user's permissions.
 * @example
 * <div *permissions="['CHATS.VIEW', element.hasCreatedThis]">Content</div>
 * @example
 * <div *permissions="['CHATS.VIEW']; licenseOnly: true">Content</div>
 */
@Directive({
  selector: '[permissions]',
})
export class PermissionsDirective implements OnInit {
  private hasView = false;
  private _licenseOnly = false;

  /**
   *  - 'all' (default): returns true if the user has all the license permissions.
   *  - 'any': returns true if the user has at least one of the license permissions.
   * */
  @Input() permissionsLicenseMode: 'all' | 'any' = 'all';
  /**
   * @description List of permissions to check.
   */
  @Input() permissions: string[] = [];
  /**
   * @example <div *permissions="['CHATS.VIEW']; else anotherContent">Content</div
   * <ng-template #anotherContent></ng-template>
   */
  @Input() permissionsElse?: TemplateRef<any>;
  /**
   * @description If true, only checks license permissions.
   */
  @Input() set permissionsLicenseOnly(value: boolean) {
    this._licenseOnly = value;
    this.changeView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionsService: PermissionsService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.permissionsService.permissionChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.changeView();
      });
  }

  /**
   * @description This method is used to check if the user has the required permissions, and show or hide the element accordingly.
   */
  private changeView(): void {
    if (this._licenseOnly) {
      this.hasView = this.permissionsService.checkLicensePermissions(this.permissions, this.permissionsLicenseMode);
    } else {
      this.hasView = this.permissionsService.checkPermissions(this.permissions);
    }

    if (this.hasView) {
      if (!this.viewContainer.length) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else if (this.permissionsElse) {
      this.viewContainer.createEmbeddedView(this.permissionsElse);
    } else {
      this.viewContainer.clear();
    }
  }
}
