import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCompanyAttributes from './company-attributes.reducer';
import { selectBlogLoadingState } from '@modules/blog/store/blog.selectors';

export const companyAttributesState =
  createFeatureSelector<fromCompanyAttributes.State>(
    fromCompanyAttributes.companyAttributesFeatureKey,
  );

export const attributes = createSelector(
  companyAttributesState,
  (state) => state.attributes,
);
export const attributeValues = createSelector(
  companyAttributesState,
  (state) => state.attributeValues,
);
export const selectAttributesWithValues = createSelector(
  companyAttributesState,
  (state) => state.attributesWithValues,
);
export const selectTotalAttributes = createSelector(
  companyAttributesState,
  (state) => state.totalAttributes,
);

export const selectAttributesLoadingState = createSelector(
  companyAttributesState,
  (state) => state.isLoading,
);
