const ATTRIBUTE_ICON = `
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M6 6H6.0075M6.375 6C6.375 6.20711 6.20711 6.375 6 6.375C5.79289 6.375 5.625 6.20711 5.625 6C5.625 5.79289 5.79289 5.625 6 5.625C6.20711 5.625 6.375 5.79289 6.375 6Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.20294 8.95294C1.94352 8.69351 1.8138 8.5638 1.72104 8.41243C1.6388 8.27822 1.57819 8.1319 1.54145 7.97885C1.5 7.80622 1.5 7.62277 1.5 7.25589L1.5 3.9C1.5 3.05992 1.5 2.63988 1.66349 2.31901C1.8073 2.03677 2.03677 1.8073 2.31901 1.66349C2.63988 1.5 3.05992 1.5 3.9 1.5L7.25589 1.5C7.62277 1.5 7.80622 1.5 7.97885 1.54145C8.1319 1.57819 8.27822 1.6388 8.41243 1.72104C8.5638 1.8138 8.69351 1.94352 8.95294 2.20294L14.7044 7.95441C15.5955 8.84545 16.041 9.29097 16.2079 9.80471C16.3547 10.2566 16.3547 10.7434 16.2079 11.1953C16.041 11.709 15.5955 12.1545 14.7044 13.0456L13.0456 14.7044C12.1545 15.5955 11.709 16.041 11.1953 16.2079C10.7434 16.3547 10.2566 16.3547 9.80471 16.2079C9.29097 16.041 8.84545 15.5955 7.95441 14.7044L2.20294 8.95294Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
`;

export default ATTRIBUTE_ICON;
