const INTERSECT_COMMUNITY_ICON = `
<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1581_609)">
<path d="M7.55208 13.7135C10.7737 13.7135 13.3854 11.1018 13.3854 7.88015C13.3854 4.65849 10.7737 2.04681 7.55208 2.04681C4.33042 2.04681 1.71875 4.65849 1.71875 7.88015C1.71875 11.1018 4.33042 13.7135 7.55208 13.7135Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M12.5521 18.7135C15.7737 18.7135 18.3854 16.1018 18.3854 12.8801C18.3854 9.65849 15.7737 7.04681 12.5521 7.04681C9.33042 7.04681 6.71875 9.65849 6.71875 12.8801C6.71875 16.1018 9.33042 18.7135 12.5521 18.7135Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</g>
<defs>
<clipPath id="clip0_1581_609">
<rect width="20" height="20" fill="white" transform="translate(0.0517578 0.380127)"/>
</clipPath>
</defs>
</svg>
`;

export default INTERSECT_COMMUNITY_ICON;
