import { Injectable } from '@angular/core';
import { PortalRole } from '../../shared/constants/portal-role';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { selectCurrentCompany } from '../../state/user/user.reducer';

/**
 * @description This service is used to check if the user has the required permissions. Used in the permissions directive and route permission guard, but can also be used separately.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  permissionChange$ = new ReplaySubject<void>();
  role: PortalRole;
  role$ = this.store
    .select(selectCurrentCompany)
    .pipe(map((company) => company?.role));

  private _licensePermissions: string[] = [];
  private _portalPermissions: string[] = [];
  /**
   * @deprecated
   */
  private _additionalPermissions: string[] = [];
  private _allPermissions: string[] = [];

  constructor(private store: Store) {
    this.store.select(selectCurrentCompany).subscribe((company) => {
      this.role = company?.role as PortalRole;
      this._licensePermissions = company?.licencePermissions || [];
      this._portalPermissions = company?.portalPermissions || [];
      this._additionalPermissions = Object.keys(
        company?.objectPermissions || {},
      );

      this._allPermissions = [
        ...this._licensePermissions,
        ...this._portalPermissions,
        ...this._additionalPermissions,
      ];

      this.permissionChange$.next();
    });
  }

  get licensePermissions() {
    return this._licensePermissions;
  }

  get portalPermissions() {
    return this._portalPermissions;
  }

  get additionalPermissions() {
    return this._additionalPermissions;
  }

  get permissions() {
    return this._allPermissions;
  }

  hasRole(role: PortalRole): boolean {
    return this.role === role;
  }

  get isAdmin(): boolean {
    return this.hasRole(PortalRole.ADMIN);
  }

  get isContributor(): boolean {
    return this.hasRole(PortalRole.CONTRIBUTOR);
  }

  get isManager(): boolean {
    return this.hasRole(PortalRole.MANAGER);
  }

  /**
   * @description This function is used to check if the user has ALL of the required permissions.
   * @example
   * this.permissionsService.checkPermissionStrict(['CHATS.VIEW', 'CHATS.EDIT', 'CHATS.DELETE', 'ADMIN']);
   * @param permission - List of permission to check. Can also include roles and booleans (used in individual rows in tables for example).
   * @returns boolean
   */
  checkPermissionStrict(
    permission: (string | PortalRole | boolean)[],
  ): boolean {
    return permission.every((p) => {
      switch (typeof p) {
        case 'string':
          return (
            this._portalPermissions.includes(p) || this.hasRole(p as PortalRole)
          );
        case 'boolean':
          return p;
        default:
          return this.hasRole(p);
      }
    });
  }

  /**
   * @description This function is used to check if the user has SOME of the required permissions.
   * @example
   * this.permissionsService.checkPermissions(['CHATS.VIEW', 'CHATS.EDIT', 'CHATS.DELETE', 'ADMIN']);
   * @param permission - List of permission to check. Can also include roles and booleans (used in individual rows in tables for example).
   * @returns boolean
   */
  checkPermissions(permission: (string | PortalRole | boolean)[]): boolean {
    return permission.some((p) => {
      switch (typeof p) {
        case 'string':
          return (
            this._portalPermissions.includes(p) || this.hasRole(p as PortalRole)
          );
        case 'boolean':
          return p;
        default:
          return this.hasRole(p);
      }
    });
  }

  /**
   * @description This function is used to check if the user has ALL of the required license permissions.
   * @example
   * this.permissionsService.checkLicensePermissions(['CHATS.VIEW', 'CHATS.EDIT', 'CHATS.DELETE']);
   * @param permission - The permission to check
   * @param mode - 'all' | 'any':
   *  - 'all' (default): returns true if the user has all the license permissions.
   *  - 'any': returns true if the user has at least one of the license permissions.
   * @returns boolean
   */
  checkLicensePermissions(permission: string[], mode: 'all' | 'any' = 'all'): boolean {
    if (mode === 'all') {
      return permission.every((p) => this._licensePermissions.includes(p));
    }

    return permission.some((p) => this._licensePermissions.includes(p));
  }
}
