const LIST_ICON = `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.14307 10.0002C7.14307 9.53993 7.51616 9.16683 7.9764 9.16683L17.9764 9.16683C18.4366 9.16683 18.8097 9.53993 18.8097 10.0002C18.8097 10.4604 18.4366 10.8335 17.9764 10.8335L7.9764 10.8335C7.51616 10.8335 7.14307 10.4604 7.14307 10.0002Z" stroke="none"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.14307 5.00016C7.14307 4.53993 7.51616 4.16683 7.9764 4.16683L17.9764 4.16683C18.4366 4.16683 18.8097 4.53993 18.8097 5.00016C18.8097 5.4604 18.4366 5.8335 17.9764 5.8335L7.9764 5.8335C7.51616 5.8335 7.14307 5.4604 7.14307 5.00016Z" stroke="none"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.14307 15.0002C7.14307 14.5399 7.51616 14.1668 7.9764 14.1668L17.9764 14.1668C18.4366 14.1668 18.8097 14.5399 18.8097 15.0002C18.8097 15.4604 18.4366 15.8335 17.9764 15.8335L7.9764 15.8335C7.51616 15.8335 7.14307 15.4604 7.14307 15.0002Z" stroke="none"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.14307 10.0002C2.14307 9.07969 2.88926 8.3335 3.80973 8.3335C4.73021 8.3335 5.4764 9.07969 5.4764 10.0002C5.4764 10.9206 4.73021 11.6668 3.80973 11.6668C2.88926 11.6668 2.14307 10.9206 2.14307 10.0002Z" stroke="none"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.14307 5.00016C2.14307 4.07969 2.88926 3.3335 3.80973 3.3335C4.73021 3.3335 5.4764 4.07969 5.4764 5.00016C5.4764 5.92064 4.73021 6.66683 3.80973 6.66683C2.88926 6.66683 2.14307 5.92064 2.14307 5.00016Z" stroke="none"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.14307 15.0002C2.14307 14.0797 2.88926 13.3335 3.80973 13.3335C4.73021 13.3335 5.4764 14.0797 5.4764 15.0002C5.4764 15.9206 4.73021 16.6668 3.80973 16.6668C2.88926 16.6668 2.14307 15.9206 2.14307 15.0002Z" stroke="none"/>
</svg>
`;

export default LIST_ICON;
