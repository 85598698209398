import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ErrorStateMatcher,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';

import { DomSanitizer } from '@angular/platform-browser';

import { MatSelectDirective } from '@material/select/directives';

import { CUSTOM_ICONS } from './custom-icons';
import { InfoButtonComponent } from './info-button/info-button.component';
import { ToggleComponent } from './toggle/toggle.component';
import { CustomErrorStateMatcher } from './input/error-state-matcher';
import { FocusStateDirective } from './input/focus-state.directive';

@NgModule({
  declarations: [
    MatSelectDirective,
    InfoButtonComponent,
    ToggleComponent,
    FocusStateDirective,
  ],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatMenuModule,
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSelectDirective,
    MatTooltipModule,
    MatMenuModule,
    InfoButtonComponent,
    ToggleComponent,
    FocusStateDirective,
  ],
})
export class MaterialModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.setDefaultFontSetClass('material-icons-round');

    for (const icon of CUSTOM_ICONS) {
      iconRegistry.addSvgIconLiteral(
        icon.name,
        sanitizer.bypassSecurityTrustHtml(icon.svg),
      );
    }
  }
}
