import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GeneralStateEffects } from './general-state.effects';
import * as fromGeneralState from './general-state.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([GeneralStateEffects]),
    StoreModule.forFeature(
      fromGeneralState.generalStatesFeatureKey,
      fromGeneralState.reducer,
    ),
  ],
})
export class GeneralStateModule {}
