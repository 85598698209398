import { Directive, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFocusState]',
})
export class FocusStateDirective {
  constructor(@Self() @Optional() private control: NgControl) {}

  @HostListener('focus')
  onFocus() {
    if (!this.control) {
      return;
    }
    this.control.control.focused = true;
  }

  @HostListener('blur')
  onBlur() {
    if (!this.control) {
      return;
    }
    this.control.control.focused = false;
  }
}
