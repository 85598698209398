import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { concatMap } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import { SystemAlertsActions } from './system-alerts.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SystemAlertComponent } from '@material/system-alert/system-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../shared/components/info-dialog/info-dialog.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class SystemAlertsEffects {
  showSystemAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemAlertsActions.showSystemAlert),
      concatMap(({ title, message, type }) => {
        this.showSystemAlert(title, message, type);
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  // TODO: Leave only one - success or info system alert
  showSuccessSystemAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemAlertsActions.showSuccessSystemAlert),
      concatMap(({ title, message }) => {
        this.showSystemAlert(title, message, 'info');
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  showErrorSystemAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemAlertsActions.showErrorSystemAlert),
      concatMap(({ title, message }) => {
        this.showSystemAlert(title, message, 'error');
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  showInfoSystemAlert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemAlertsActions.showInfoSystemAlert),
      concatMap(({ title, message }) => {
        this.showSystemAlert(title, message, 'info');
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  showInfoDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemAlertsActions.showInfoDialog),
      concatMap(({ title, message }) => {
        this.showInfoDialog(title, message);
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  showConfirmationDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemAlertsActions.showConfirmationDialog),
      concatMap(
        ({
          title,
          message,
          actionName,
          cancelName,
          modalType = 'confirm',
          action,
          cancel,
          close,
        }) => {
          this.showConfirmationDialog({
            title,
            message,
            actionName,
            cancelName,
            modalType,
            action,
            cancel,
            close,
          });
          return EMPTY as Observable<{ type: string }>;
        },
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {}

  private showSystemAlert(title, message, type, duration = 8000) {
    this.snackbar.openFromComponent(SystemAlertComponent, {
      data: {
        title,
        message,
        action: 'Retry',
        type,
      },
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration,
    });
  }

  private showInfoDialog(title, message) {
    return this.dialog.open<
      InfoDialogComponent,
      { title: string; message: string }
    >(InfoDialogComponent, {
      data: {
        title,
        message,
      },
      maxWidth: '480px',
    });
  }

  private showConfirmationDialog({
    title,
    message,
    actionName,
    cancelName,
    modalType,
    action,
    cancel,
    close,
  }) {
    return this.dialog.open<
      ConfirmationDialogComponent,
      {
        title: string;
        message: string;
        actionName: string;
        cancelName: string;
        modalType: 'confirm' | 'remove';
        action: () => void;
        cancel?: () => void;
        close?: () => void;
      }
    >(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        actionName,
        cancelName,
        modalType,
        action,
        cancel,
        close,
      },
      maxWidth: '464px',
    });
  }
}
