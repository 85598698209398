<label class="info-content">
  <button
    class="info-button"
    [matTooltip]="infoText"
    matTooltipPosition="below"
  >
    <mat-icon>info</mat-icon>
  </button>
  <ng-content></ng-content>
</label>
