const ADMIN_ICON = `
<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1581_591)">
<path d="M18.3854 17.8801V16.2135C18.3854 14.6603 17.3231 13.3552 15.8854 12.9851M12.9688 3.12243C14.1903 3.61692 15.0521 4.81455 15.0521 6.21346C15.0521 7.61237 14.1903 8.81 12.9688 9.30449M14.2188 17.8801C14.2188 16.327 14.2188 15.5504 13.965 14.9378C13.6267 14.1211 12.9778 13.4722 12.161 13.1339C11.5485 12.8801 10.7719 12.8801 9.21875 12.8801H6.71875C5.16561 12.8801 4.38904 12.8801 3.77647 13.1339C2.95971 13.4722 2.3108 14.1211 1.97248 14.9378C1.71875 15.5504 1.71875 16.327 1.71875 17.8801M11.3021 6.21346C11.3021 8.05441 9.8097 9.54679 7.96875 9.54679C6.1278 9.54679 4.63542 8.05441 4.63542 6.21346C4.63542 4.37251 6.1278 2.88013 7.96875 2.88013C9.8097 2.88013 11.3021 4.37251 11.3021 6.21346Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</g>
<defs>
<clipPath id="clip0_1581_591">
<rect width="20" height="20" fill="white" transform="translate(0.0517578 0.380127)"/>
</clipPath>
</defs>
</svg>
`;

export default ADMIN_ICON;
