import { Component, Inject, OnInit, DestroyRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RemoveModalComponent } from '../remove-modal/remove-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RemoveModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
      actionName: string;
      cancelName?: string;
      modalType?: 'confirm' | 'remove';
      action: () => void;
      cancel?: () => void;
      close?: () => void;
    },
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.dialogRef
      .backdropClick()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.data.close();
      });
  }

  cancel(): void {
    if (this.data.cancel) {
      this.data.cancel();
    }
    this.dialogRef.close(false);
  }

  confirm(): void {
    this.data.action();
    this.dialogRef.close(true);
  }
}
