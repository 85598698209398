<div class="system-alert system-alert--{{ data.type }}">
  <div class="system-alert__icon" *ngIf="data.type !== 'info'">
    <mat-icon *ngIf="data.type === 'error'">error</mat-icon>
    <mat-icon *ngIf="data.type === 'success'">rocket_launch</mat-icon>
  </div>
  <div class="system-alert__title" *ngIf="data.title">
    {{ data.title }}
  </div>
  <div class="system-alert__message" *ngIf="data.message">
    {{ data.message }}
  </div>
  <div class="system-alert-actions" *ngIf="data.actions">
    {{ data.actions }}
  </div>
  <div class="system-alert__close" *ngIf="data.type !== 'info'">
    <button (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
</div>
