import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { GeneralStateActions } from './general-state.actions';

export const generalStatesFeatureKey = 'generalStates';

export interface State {
  showSideNav: boolean;
  showHeader: boolean;
  hasUnsavedChanges: boolean;
}

export const initialState: State = {
  showSideNav: true,
  showHeader: true,
  hasUnsavedChanges: false,
};

export const reducer = createReducer(
  initialState,
  on(GeneralStateActions.toggleHeader, (state) => ({
    ...state,
    showHeader: !state.showHeader,
  })),
  on(GeneralStateActions.toggleSidenav, (state) => ({
    ...state,
    showSideNav: !state.showSideNav,
  })),
  on(GeneralStateActions.setSidenav, (state, { isShow }) => ({
    ...state,
    showSideNav: isShow,
  })),
  on(GeneralStateActions.markUnsavedChanges, (state, { unsavedChanges }) => ({
    ...state,
    hasUnsavedChanges: unsavedChanges,
  })),
);

export const generalStatesFeature = createFeature({
  name: generalStatesFeatureKey,
  reducer,
  extraSelectors: ({ selectGeneralStatesState }) => ({
    selectShowSideNav: createSelector(
      selectGeneralStatesState,
      (state) => state.showSideNav,
    ),
    selectShowHeader: createSelector(
      selectGeneralStatesState,
      (state) => state.showHeader,
    ),
    selectHasUnsavedChanges: createSelector(
      selectGeneralStatesState,
      (state) => state.hasUnsavedChanges,
    ),
  }),
});

export const { selectShowSideNav, selectShowHeader, selectHasUnsavedChanges } =
  generalStatesFeature;
