import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  Attribute,
  AttributeValue,
  AttributeWithValues,
} from '../../shared/interfaces';
import { ApiErrorInterface } from '@shared/interfaces/api-error.interface';

export const CompanyAttributesActions = createActionGroup({
  source: 'CompanyAttributes',
  events: {
    'Loading State Attributes': props<{ state: boolean }>(),
    'Get Company Attributes': props<{ companyId: number }>(),
    'Get Company Attributes Success': props<{ attributes: Attribute[] }>(),
    'Create Attribute': props<{ companyId: number; attributeLabel: string }>(),
    'Create Attribute Success': props<{ attribute: Attribute }>(),
    'Create Attribute Failure': props<{ error: ApiErrorInterface }>(),
    'Update Attribute': props<{
      companyId: number;
      attributeLabel: string;
      attributeId: number;
    }>(),
    'Update Attribute Success': props<{ attribute: Attribute }>(),
    'Update Attribute Failure': props<{ error: ApiErrorInterface }>(),
    'Delete Attribute': props<{ companyId: number; attributeId: number }>(),
    'Delete Attribute Success': props<{ attributeId: number }>(),
    'Get Company Attribute Values': props<{
      companyId: number;
      attributeId: number | string;
    }>(),
    'Get Company Attribute Values Success': props<{
      attributeValues: AttributeValue[];
    }>(),
    'Create Attribute Values': props<{
      companyId: number;
      attributeId: number;
      attributeValues: string[];
    }>(),
    'Create Attribute Values Success': props<{
      attributeValues: AttributeValue[];
    }>(),
    'Get Company Attributes With Values': props<{ companyId: number }>(),
    'Update Attribute Value': props<{
      companyId: number;
      attributeId: number;
      attributeValueId: number;
      attributeValueLabel: string;
    }>(),
    'Update Attribute Value Success': props<{
      attributeValue: AttributeValue;
    }>(),
    'Update Attribute Value Failure': props<{ error: ApiErrorInterface }>(),
    'Delete Attribute Value': props<{
      companyId: number;
      attributeId: number;
      attributeValueId: number;
    }>(),
    'Delete Attribute Value Success': props<{
      attributeValueId: number;
      attribute: Attribute | null;
    }>(),
    'Get Company Attributes With Values Success': props<{
      attributes: AttributeWithValues[];
    }>(),
    'Set Company Attribute Total': props<{
      totalAttributes: number;
    }>(),
  },
});
