const TEAMS_ICON = `
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_12550_4120)">
<path d="M12.5084 16.8C12.5084 15.2158 12.5084 14.4237 12.2496 13.7989C11.9045 12.9658 11.2426 11.4539 10.4095 11.1088C9.78467 10.85 8.99257 10.85 7.40837 10.85H5.70837C4.97417 10.85 4.18207 10.85 3.55725 11.1088C2.72415 11.4539 2.06226 12.9658 1.71718 13.7989C1.45837 14.4237 1.45837 15.2158 1.45837 16.8M10.3834 4.9C10.3834 6.77776 8.86114 8.3 6.98337 8.3C5.1056 8.3 3.58337 6.77776 3.58337 4.9C3.58337 3.02223 5.1056 1.5 6.98337 1.5C8.86114 1.5 10.3834 3.02223 10.3834 4.9Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.7751 9.43323C13.5232 9.43323 12.5084 8.41841 12.5084 7.16657C12.5084 5.91472 13.5232 4.8999 14.7751 4.8999C16.0269 4.8999 17.0417 5.91472 17.0417 7.16657C17.0417 8.41841 16.0269 9.43323 14.7751 9.43323Z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.9709 16.6928V14.9928C16.9709 13.4086 15.6748 12.0774 14.2084 11.7" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
`;

export default TEAMS_ICON;
