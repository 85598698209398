import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { UserService } from '../services/user.service';
import { UserActions } from '../../state/user/user.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store, private userService: UserService) {}
  refreshTokenInProgress: boolean;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    request = this.addAuthHeader(request);
    return next.handle(request).pipe(
      catchError((err) => {
        return this.handleResponseError(err, request, next);
      }),
    );
  }

  addAuthHeader(request): HttpRequest<any> {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    return request;
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.userService
        .refreshToken(localStorage.getItem('refreshToken'))
        .pipe(
          tap(({ accessToken, refreshToken }) => {
            this.refreshTokenInProgress = false;

            this.store.dispatch(
               UserActions.refreshTokenSuccess({ accessToken, refreshToken }),
            );

            setTimeout(() => {
              this.tokenRefreshedSource.next(true);
            });
          }),
          catchError(() => {
            this.refreshTokenInProgress = false;
            this.store.dispatch(
              UserActions.logoutUser({ clearTokensOnly: true }),
            );
            return of();
          }),
        );
    }
  }

  handleResponseError(error, request?, next?): Observable<any> {
    if (error.status === 401) {
      if (localStorage.getItem('refreshToken')) {
        return this.refreshToken().pipe(
          switchMap(() => {
            request = this.addAuthHeader(request);
            return next.handle(request);
          }),
          catchError((e) => {
            if (e.status !== 401) {
              return this.handleResponseError(e);
            } else {
              this.store.dispatch(
                UserActions.logoutUser({ clearTokensOnly: true }),
              );
            }
          }),
        );
      } else {
        this.store.dispatch(UserActions.logoutUser({ clearTokensOnly: true }));
      }
    }

    return throwError(error);
  }
}
